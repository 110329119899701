import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITask } from 'app/shared/model/task.model';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class TasksForStageElementService {
    public resourceUrl = SERVER_API_URL + 'api/projects';
    public tasksResourceUrl = SERVER_API_URL + 'api/tasks';
    public stagesResourceUrl = SERVER_API_URL + 'api/stages';

    constructor(private http: HttpClient) {}

    query(params: { stageId: number; elementId: number | null }, req?: any): Observable<HttpResponse<ITask[]>> {
        const options = createRequestOption(req);
        const url = params.elementId
            ? `${this.stagesResourceUrl}/${params.stageId}/elements/${params.elementId}/tasks`
            : `${this.stagesResourceUrl}/${params.stageId}/tasks`;
        return this.http.get<ITask[]>(url, {
            params: options,
            observe: 'response'
        });
    }

    find(id: number): Observable<HttpResponse<ITask>> {
        return this.http.get<ITask>(`${this.tasksResourceUrl}/${id}`, { observe: 'response' });
    }
}
