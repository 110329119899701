import { IActualLabourComponent } from './actual-labour-component.model';
import { IActualMaterialComponent } from './actual-material-component.model';
import { ITask, TaskType } from 'app/shared/model/task.model';
import { IMaterial } from 'app/shared/model/material.model';
import { IBuildUp } from 'app/shared/model/bp.model';
import { IMaterialCategory } from 'app/shared/model/material-category.model';

export interface IQuoterTaskCost {
    qty: number;
    materialCost: number;
    laborCost: number;
    total: number;
}

export interface IScheduleTask {
    spec?: string;
    id?: number;
    action?: string;
    projectId?: number;
    note?: string;
    copyId?: number;
    unitValue?: number;
    actualLabourComponents?: IActualLabourComponent[];
    actualMaterialComponents?: IActualMaterialComponent[];
    componentarea?: string;
    schedulearea?: string;
    scheduleArea?: string;
    task?: string;
    buildUps?: IBuildUp[];
    oldTaskName?: string;
    stageId?: number;
    elementId?: number;
    unit?: string;
    unitPlural?: string;
    area?: string;
    stage?: string;
    element?: string;
    taskId?: number;
    oldTaskId?: number;
    areaId?: number;
    unitId?: number;
    componentArea?: string;
    componentAreaId?: number;
    scheduleAreaId?: number;
    ratio?: number;
    displayName?: string;
    isProvisional?: boolean;
    isSchedulerProvisional?: boolean;
    isSchedulerSubcontract?: boolean;
    isSubcontract?: boolean;
    provisionalCost?: number;
    materialCategories?: IMaterialCategory[];
    primeMaterialId?: number;
    primeMaterialName?: string;
    primeMaterialIds?: number[];
    primeMaterialCost?: number;
    primeMaterialCostAvailable?: boolean;
    materialUrlRef?: string;
    clientSupplied?: boolean;
    markedAsDeleted?: boolean;
    drawingRef?: string;
    referenceNumber?: string;
    type?: TaskType;

    newTaskRequestId?: number;
    specification?: string;

    taskTotal?: IQuoterTaskCost;
    _defaultUnitValue?: number;

    _materials?: IMaterial[];
    _uuid?: string;

    highlight?: boolean;

    cssElement?: string,
    cssElementId?: number,

    buildUp?: string;
    buildUpId?: number;
}

export class ScheduleTask implements IScheduleTask {
    constructor(
        public spiritUUID?: string,
        public id?: number,
        public action?: string,
        public projectId?: number,
        public note?: string,
        public copyId?: number,
        public unitValue?: number,
        public actualLabourComponents?: IActualLabourComponent[],
        public actualMaterialComponents?: IActualMaterialComponent[],
        public materialCategories?: IMaterialCategory[],
        public componentarea?: string,
        public schedulearea?: string,
        public scheduleArea?: string,
        public task?: string,
        public unit?: string,
        public unitPlural?: string,
        public area?: string,
        public stage?: string,
        public element?: string,
        public taskId?: number,
        public stageId?: number,
        public elementId?: number,
        public unitId?: number,
        public componentArea?: string,
        public componentAreaId?: number,
        public scheduleAreaId?: number,
        public ratio?: number,
        public spec?: string,
        public displayName?: string,
        public isSubcontract?: boolean,
        public isProvisional?: boolean,
        public isSchedulerProvisional?: boolean,
        public isSchedulerSubcontract?: boolean,
        public provisionalCost?: number,
        public primeMaterialId?: number,
        public primeMaterialCost?: number,
        public primeMaterialCostAvailable?: boolean,
        public clientSupplied?: boolean,
        public markedAsDeleted?: boolean,
        public materialUrlRef?: string,
        public drawingRef?: string,
        public taskTotal?: IQuoterTaskCost,
        public _fullTask?: ITask,
        public newTaskRequestId?: number,
        public specification?: string
    ) {}
}
