import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IScheduleArea, IScheduleAreaCache } from '../model/schedule-area.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ITag } from 'app/shared/model/tag.model';

@Injectable({ providedIn: 'root' })
export class ScheduleAreaService {
    public resourceUrl = SERVER_API_URL + 'api/schedule-areas';

    constructor(private http: HttpClient) {
    }

    create(scheduleArea: IScheduleArea): Observable<HttpResponse<IScheduleArea>> {
        return this.http.post<IScheduleArea>(this.resourceUrl, scheduleArea, { observe: 'response' });
    }

    update(scheduleArea: IScheduleArea): Observable<HttpResponse<IScheduleArea>> {
        return this.http.put<IScheduleArea>(this.resourceUrl, scheduleArea, { observe: 'response' });
    }

    duplicate(projectId: number, scheduleArea: IScheduleArea): Observable<HttpResponse<IScheduleArea>> {
        return this.http.post<IScheduleArea>(`${SERVER_API_URL}api/projects/${projectId}/schedule-areas/${scheduleArea.id}/duplicate`, null, { observe: 'response' });
    }

    position(scheduleArea: IScheduleArea, position: number): Observable<HttpResponse<IScheduleArea>> {
        return this.http.post<IScheduleArea>(`${this.resourceUrl}/${scheduleArea.id}/position/${position}`, null, { observe: 'response' });
    }

    batchUpdate(scheduleAreas: IScheduleArea[], deleteScheduleAreas: number[] = []): Observable<HttpResponse<IScheduleArea[]>> {
        const body: IScheduleAreaCache = {
            timeStamp: Date.now(),
            scheduleAreas,
            deleteAreas: deleteScheduleAreas
        };

        return this.http.post<IScheduleArea[]>(`${this.resourceUrl}/batch`, body, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<IScheduleArea>> {
        return this.http.get<IScheduleArea>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IScheduleArea[]>> {
        const options = createRequestOption(req);
        return this.http.get<IScheduleArea[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    findDefaultUnitValue(scheduleAreaId: number, componentAreaId: number): Observable<HttpResponse<number>> {
        return this.http.get<number>(`${this.resourceUrl}/${scheduleAreaId}/component-area/${componentAreaId}`, { observe: 'response' });
    }

    move(areaId: number, tagId: number): Observable<HttpResponse<ITag>> {
        return this.http.post<ITag>(`${this.resourceUrl}/${areaId}/tag/${tagId}`, null, { observe: 'response' });
    }
}
