import { Component, OnDestroy } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { Subscription } from 'rxjs';
import { skip } from "rxjs/operators";

/**
 * Dashboard
 */
@Component({
    selector: 'bp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.scss']
})
export class DashboardComponent implements OnDestroy {
    private _filterStateSub = Subscription.EMPTY;
    private _currentItemSub = Subscription.EMPTY;

    constructor(
        private topMenuStateService: TopMenuStateService,
        public dashboardStore: DashboardStore,
        public dashboardService: DashboardService
    ) {
        this._currentItemSub = this.topMenuStateService.currentItem$
            .pipe(skip(1))
            .subscribe(item => {
                this.dashboardStore.dashboardMode = null;
            });

        this.dashboardStore.project = this.topMenuStateService.project;

        dashboardService.setDefaults().then(() => {
            dashboardService.init().then(() => {
                this._filterStateSub = this.dashboardStore.filterState$.subscribe(() => {
                    this.dashboardService.updateItemsIds();
                    this.dashboardService.updateValueColumns();
                    this.dashboardService.updateResourceCost();
                })
            });
        });
    }

    ngOnDestroy(): void {
        this._filterStateSub.unsubscribe();
        this._currentItemSub.unsubscribe();
    }

}
