<div class="row">
    <div class="col-sm-5">
        <h5 style="line-height: 1.2">
            <span>{{scheduleTask.displayName}}{{ scheduleTask.spec ? ', ' + scheduleTask.spec : '' }}</span>
            <span class="faint">in {{scheduleTask.scheduleArea}}</span>
            <span class="faint" *ngIf="scheduleTask.isProvisional">(Provisional)</span>
            <span class="faint" *ngIf="scheduleTask.isSubcontract">(Subcontract)</span>

            <b *ngIf="scheduleTask.unitValue != null">
                ({{ scheduleTask.unitValue | number : '1.2-2' }}
                <span *ngIf="scheduleTask.unit == 'm2'">m<sup>2</sup></span>
                <span
                    *ngIf="scheduleTask.unit != 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>)
            </b>

            <div *ngIf="scheduleTask.primeMaterial">
                <span class="faint">{{ scheduleTask.primeMaterial }}</span>
            </div>
        </h5>
    </div>
    <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
        <h5 *ngFor="let total of scheduleTask.totals"
            class="flex-1 max-width-30-percents">
            {{ total || 0 | currency:'GBP' }}
        </h5>
    </div>
</div>
