import { Component, Input } from '@angular/core';
import { IElement } from 'app/shared/model/element.model';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';

@Component({
    selector: 'bp-schedule-element-for-filter-by-stage-grouping',
    templateUrl: './element.component.html',
    styleUrls: [
        '../../root-container/element.scss',
        '../../../schedule-common.scss'
    ]
})
export class ElementForFilterByStageGroupingComponent {
    @Input() stage: IStage;
    @Input() element: IElement;

    constructor(public scheduleService: ScheduleService) {
    }

    filteredByAreaAndNotMarkedAsDeletedScheduleTasks(): IScheduleTask[] {
        return _.filter(this.element.scheduleTasks, scheduleTask => {
            return !scheduleTask.markedAsDeleted;
        });
    }

    get elementTotal(): number {
        return this.filteredByAreaAndNotMarkedAsDeletedScheduleTasks()
            .reduce((total, task) => task.taskTotal.total + total, 0);
    }
}
