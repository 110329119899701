<div class="submit-for-analysis">
    <div class="modal-header flex flex-column gap-15 align-items-center position-relative">
        <button (click)="close()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>

        <div class="submit-for-analysis__title">
            Automated Floorplan<br>Analysis
        </div>
        <div class="submit-for-analysis__note">
            Note: Please allow two working days, we will notify you<br>when the dimensions are uploaded.
        </div>
    </div>

    <div class="modal-body flex flex-column align-items-center gap-20 m-t-30 m-b-10">
        <div class="flex flex-column gap-20">
            <div>
                Simply upload your documents here and we will measure and upload the dimensions of your selected areas.
            </div>
            <div *ngIf="false">
                Choose the type of service you require (follow <a href="https://buildpartner.com/#Pricing"
                                                                  class="link underline"
                                                                  target="_blank">this link</a> for further
                info.):
            </div>
            <div class="w-250" *ngIf="false">
                <select [formControl]="serviceTypeControl"
                        name="service_type"
                        id="service_type"
                        class="form-control">
                    <option [ngValue]="null" disabled>Type of service required *</option>
                    <option
                        *ngFor="let serviceOption of serviceTypes;"
                        [ngValue]="serviceOption.value">
                        {{ serviceOption.label }}
                    </option>
                </select>
            </div>

            <div class="flex flex-column gap-20">
                <div>
                    <div>
                        Existing floorplans:
                    </div>
                    <div class="flex-1">
                        <input [formControl]="existingFloorPlanURLControl"
                               class="form-control v2"
                               placeholder="Post a link to dropbox, google drive etc. or upload below"
                               id="existing-floor-plan-url"
                               name="existing-floor-plan-url"
                               type="text">
                    </div>
                    <div class="flex flex-row align-items-center gap-10">
                        <bp-project-attachments-fe-only
                            (onChanged)="existingFloorPlanFiles = $event"
                            [attachments]="existingFloorPlanAttachments"
                            [project]="project"
                            [accept]="'application/pdf'"
                            [buttonLabel]="'Upload'">
                        </bp-project-attachments-fe-only>
                    </div>
                </div>
                <div>
                    <div>
                        Proposed floorplans:
                    </div>
                    <div class="flex-1">
                        <input [formControl]="proposedFloorPlanURLControl"
                               class="form-control v2"
                               placeholder="Post a link to dropbox, google drive etc. or upload below"
                               id="proposed-floor-plan-url"
                               name="proposed-floor-plan-url"
                               type="text">
                    </div>
                    <div class="flex flex-row align-items-center gap-10">
                        <bp-project-attachments-fe-only
                            (onChanged)="proposedFloorPlanFiles = $event"
                            [attachments]="proposedFloorPlanAttachments"
                            [project]="project"
                            [accept]="'application/pdf'"
                            buttonLabel="Upload">
                        </bp-project-attachments-fe-only>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="flex gap-20 justify-content-end w-full">
                <button (click)="close()"
                        class="btn btn-secondary btn-border-radius submit-for-analysis__close"
                        type="button">
                    Close
                </button>

                <button (click)="onOkClick()"
                        [disabled]="!submitEnabled"
                        tabindex="0"
                        class="btn btn-primary btn-border-radius waves-effect submit-for-analysis__ok"
                        type="button">
                    Submit for analysis
                </button>
            </div>
        </div>
    </div>
</div>
