export interface ITag {
    id?: number;
    name?: string;
    title?: string;
    description?: string;
    icon?: string;
    tagOrder?: number;
    parentTag?: ITag;
    selected?: boolean;
}

export interface IUserTag {
    id?: number;
    tag: ITag;
    name: string;
}

export class Tag implements ITag {
    constructor(
        public id?: number,
        public name?: string,
        public title?: string,
        public description?: string,
        public icon?: string,
        public tagOrder?: number,
        public parentTag?: ITag,
        public selected?: boolean
    ) {}
}

