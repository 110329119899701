import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEXP_DECIMAL, REGEXP_DECIMAL_POS_NEG } from "app/shared/constants/patterns";
import { INewTaskRequestCreationDTO } from "app/shared/model/new-task-request.model";
import { Subscription } from "rxjs";
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';

@Component({
    selector: 'bp-task-request-modal',
    templateUrl: './task-request-modal.component.html',
    styleUrls: ['task-request-modal.scss']
})
export class TaskRequestModalComponent {
    protected data!: INewTaskRequestCreationDTO;

    protected form: FormGroup;
    protected submitted = false;

    protected infoText = 'Our qualified schedulers will add task/material to your database, whilst also verifying costs';

    protected subscriptions: Subscription[] = [];

    protected showStagesSelect = false;
    protected stagesSelectInputData: SelectInputData;

    constructor(private fb: FormBuilder,
                private scheduleService: ScheduleService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: new FormControl(this.data.name, [Validators.required]),
            specification: new FormControl(),
            materialRate: new FormControl(0, [Validators.pattern(REGEXP_DECIMAL_POS_NEG)]),
            laborRate: new FormControl(0, [Validators.pattern(REGEXP_DECIMAL_POS_NEG)]),
            total: new FormControl(0, [Validators.pattern(REGEXP_DECIMAL_POS_NEG)])
        });

        this.subscriptions.push(...[
            this.form.controls.materialRate.valueChanges.subscribe(() => this.updateTotal()),
            this.form.controls.laborRate.valueChanges.subscribe(() => this.updateTotal())
        ]);

        this.showStagesSelect = this.data.stageId == null;
        if (this.showStagesSelect) {
            this.fillStagesSelectInputData();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    protected clear(): void {
        this.activeModal.dismiss('close');
    }

    protected addTask(): void {
        this.data.name = this.form.controls.name.value;
        this.data.specification = this.form.controls.specification.value;
        this.data.materialRate = this.form.controls.materialRate.value;
        this.data.laborRate = this.form.controls.laborRate.value;
        this.data.total = this.form.controls.total.value;

        this.activeModal.close(this.data);
    }

    protected onStageSelectionChange(event: any): void {
        this.data.stageId = event.id;
    }

    private updateTotal(): void {
        this.form.controls.total.setValue(this.form.controls.materialRate.value + this.form.controls.laborRate.value);
    }

    private fillStagesSelectInputData(): void {
        this.stagesSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'stage',
            data: this.scheduleService.allStages,
            dropdownPosition: 'bottom'
        };
    }

}
