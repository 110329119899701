<td>
    <span>{{scheduleTask.displayName}}{{ scheduleTask.spec ? ', ' + scheduleTask.spec : '' }}</span>
    <span class="faint"> in {{scheduleTask.scheduleArea}}</span>
    <span class="faint" *ngIf="scheduleTask.isProvisional">(Provisional)</span>
    <span class="faint" *ngIf="scheduleTask.isSubcontract">(Subcontract)</span>
</td>

<td>
    <a *ngIf="scheduleTask.primeMaterial && scheduleTask.primeReferenceUrl"
       href="{{scheduleTask.primeReferenceUrl}}"
       target="_blank"
       rel="noopener">
        {{ scheduleTask.primeMaterial }}
    </a>
    <span *ngIf="scheduleTask.primeMaterial && !scheduleTask.primeReferenceUrl">{{ scheduleTask.primeMaterial }}</span>
</td>

<td>
    <span *ngIf="scheduleTask.unitValue != null">
        {{scheduleTask.unitValue | number : '1.2-2' }}
        <span *ngIf="scheduleTask.unit == 'm2'">m<sup>2</sup></span>
        <span
            *ngIf="scheduleTask.unit != 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>
    </span>
</td>

<td>
    <span
        *ngIf="!scheduleTask.isProvisional">{{ (scheduleTask.labourTotals[0] / scheduleTask.unitValue) || 0 | currency:'GBP' }}</span>
</td>

<td>
    <span
        *ngIf="!scheduleTask.isProvisional">{{ (scheduleTask.materialTotals[0] / scheduleTask.unitValue) || 0 | currency:'GBP' }}</span>
</td>

<td class="text-right">
    <span>{{ scheduleTask.totals[0] || 0 | currency:'GBP' }}</span>
</td>
