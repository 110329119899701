import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITask, ITaskInfo } from '../model/task.model';
import { ITaskTotal } from 'app/shared/model/task-total.model';
import { IShortTask } from 'app/shared/model/short-task.model';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { IMaterial } from "app/shared/model/material.model";

export interface ITaskTotalComponentInput {
    id: number;
    qty: number;
}

type EntityResponseType = HttpResponse<ITask>;
type EntityArrayResponseType = HttpResponse<ITask[]>;

@Injectable({ providedIn: 'root' })
export class TaskService {
    public resourceUrl = SERVER_API_URL + 'api/tasks';
    public elementsResourceUrl = SERVER_API_URL + 'api/elements';

    constructor(private http: HttpClient) {
    }

    create(task: ITask): Observable<EntityResponseType> {
        return this.http.post<ITask>(this.resourceUrl, task, { observe: 'response' });
    }

    update(task: ITask): Observable<EntityResponseType> {
        return this.http.put<ITask>(this.resourceUrl, task, { observe: 'response' });
    }

    updateShort(shortTask: IShortTask): Observable<EntityResponseType> {
        return this.http.put<ITask>(`${this.resourceUrl}/short`, shortTask, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<ITask>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<ITask[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    queryByElement(id: number): Observable<EntityArrayResponseType> {
        return this.http.get<ITask[]>(`${this.elementsResourceUrl}/${id}/tasks`, { observe: 'response' });
    }

    materials(id: number): Observable<HttpResponse<IMaterial[]>> {
        return this.http.get<IMaterial[]>(`${this.resourceUrl}/${id}/materials`, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    queryForGroupAdding(searchValue: string | null, page = 0, pageSize = 9999): Observable<HttpResponse<ITaskInfo[]>> {
        let url = `${this.resourceUrl}/info?page=${page}&size=${pageSize}&searchBy=task`;
        if (searchValue) {
            url = url + `&searchValue=${searchValue}`;
        }
        return this.http.get<ITaskInfo[]>(url, { observe: 'response' });
    }

    taskTotals(
        materialCategoryIds: number[],
        materialIds: ITaskTotalComponentInput[],
        labourIds: ITaskTotalComponentInput[]
    ): Observable<HttpResponse<ITaskTotal[]>> {
        const body = {
            materialCategoryIds,
            materialIds,
            labourIds
        };

        return this.http.post<ITaskTotal[]>(`${this.resourceUrl}/task-total`, body, { observe: 'response' });
    }
}
