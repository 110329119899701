import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ITask } from 'app/shared/model/task.model';
import { TaskService } from 'app/shared/dataservices/task.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { IElement } from 'app/shared/model/element.model';
import { IStage } from 'app/shared/model/stage.model';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { ITrade } from 'app/shared/model/trade.model';
import { IMaterial } from 'app/shared/model/material.model';
import { TradeService } from 'app/shared/dataservices/trade.service';
import { ILabourComponent, LabourComponent } from 'app/shared/model/labour-component.model';
import { IMaterialComponent, MaterialComponent } from 'app/shared/model/material-component.model';
import { IUnit } from 'app/shared/model/unit.model';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { AvailableMaterialService } from 'app/shared/dataservices/available-material.service';
import * as _ from 'lodash';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { finalize } from 'rxjs/operators';
import { SearchBySelectInputData } from 'app/shared/components/common/search-by-select/search-by-select.component';
import {
    AddMaterialCategoryModalService
} from 'app/shared/components/common/add-material-category-modal/add-material-category-modal.service';
import { ElementService } from 'app/shared/dataservices/element.service';
import { StageService } from 'app/shared/dataservices/stage.service';
import { IBuildUp, ICssElement } from 'app/shared/model/bp.model';
import { CssElementService } from 'app/shared/dataservices/css-element.service';
import {
    AddCssElementModalService
} from 'app/shared/components/common/add-css-element-modal/add-css-element-modal.service';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';
import { AddBuildUpModalService } from 'app/shared/components/common/add-build-up-modal/add-build-up-modal.service';

@Component({
    selector: 'bp-task-update',
    templateUrl: './task-update.component.html'
})
export class TaskUpdateComponent implements OnInit, OnDestroy {
    protected task: ITask;
    protected isSaving: boolean;

    protected componentareas: IComponentArea[];
    protected units: IUnit[];
    protected trades: ITrade[];
    protected elements: IElement[];
    protected stages: IStage[];

    protected NA = 'N/A';

    protected updateSubject: Subject<void> = new Subject();
    protected materialCategorySelectInputData: SearchBySelectInputData;
    protected cssElementSelectInputData: SearchBySelectInputData;
    protected buildUpSelectInputData: SearchBySelectInputData;

    constructor(
        private taskService: TaskService,
        private componentAreaService: ComponentAreaService,
        private materialCategoryService: MaterialCategoryService,
        private unitService: UnitService,
        private elementService: ElementService,
        private cssElementService: CssElementService,
        private buildUpApi: BuildUpApi,
        private stageService: StageService,
        private activatedRoute: ActivatedRoute,
        private tradeService: TradeService,
        private availableMaterialService: AvailableMaterialService,
        private addMaterialCategoryModalService: AddMaterialCategoryModalService,
        private addCssElementModalService: AddCssElementModalService,
        private addBuildUpModalService: AddBuildUpModalService
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;

        this.activatedRoute.data.subscribe(({ task }) => {
            this.task = task;

            _.each(this.task.materialComponents, (materialComponent: IMaterialComponent) => {
                materialComponent['_searchBySelectInputData'] = {
                    searchBy: 'material',
                    indexProperty: 'id',
                    titleProperty: 'material',
                    displayProperty: 'material',
                    initValue: materialComponent.material.material,
                    initIndex: materialComponent.material.id,
                    itemService: this.availableMaterialService,
                    dropdownPosition: 'top'
                };
            });

            this.fillElements();
        });

        this.componentAreaService.query().subscribe(
            (res: HttpResponse<IComponentArea[]>) => {
                this.componentareas = res.body;
            }
        );

        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );

        this.stageService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
            }
        );

        this.tradeService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.trades = res.body;
            }
        );

        this.fillMaterialCategorySelectInputData();
        this.fillCssElementSelectInputData();
        this.fillBuildUpSelectInputData();
    }

    ngOnDestroy(): void {
        this.updateSubject.unsubscribe();
    }

    protected onStageSelect(): void {
        this.fillElements();
    }

    protected previousState(): void {
        window.history.back();
    }

    protected save(): void {
        this.isSaving = true;

        const taskToSave = _.cloneDeep(this.task);
        taskToSave.materialComponents = _.map(taskToSave.materialComponents, (mc: IMaterialComponent) => {
            delete mc['_searchBySelectInputData'];
            delete mc.material['_calculatedLabelField'];
            return mc;
        });

        if (taskToSave.id !== undefined) {
            this.subscribeToSaveResponse(this.taskService.update(taskToSave));
        } else {
            this.subscribeToSaveResponse(this.taskService.create(taskToSave));
        }
    }

    protected onComponentAreaChange(): void {
        if (this.task.componentarea.componentArea !== this.NA) {
            this.task.unit = null;
        }
    }

    protected trackComponentAreaById(index: number, item: IComponentArea): number {
        return item.id;
    }

    protected trackUnitById(index: number, item: IUnit): number {
        return item.id;
    }

    protected trackElementById(index: number, item: IElement) {
        return item.id;
    }

    protected trackStageById(index: number, item: IStage): number {
        return item.id;
    }

    protected trackTradeById(index: number, item: ITrade): number {
        return item.id;
    }

    protected addLabourComponent(): void {
        this.task.labourComponents.push(new LabourComponent(-1 * Date.now(), 0, null, null));
    }

    protected removeLabourComponent(item: ILabourComponent): void {
        this.task.labourComponents.splice(this.task.labourComponents.indexOf(item), 1);
        this.updateSubject.next();
    }

    protected addMaterialComponent(): void {
        const newMaterialComponent = new MaterialComponent(-1 * Date.now(), 0, null, null);
        newMaterialComponent['_searchBySelectInputData'] = {
            searchBy: 'material',
            indexProperty: 'id',
            titleProperty: 'material',
            displayProperty: 'material',
            itemService: this.availableMaterialService,
            dropdownPosition: 'auto'
        };
        this.task.materialComponents.push(newMaterialComponent);
    }

    protected removeMaterialComponent(item: IMaterialComponent): void {
        this.task.materialComponents.splice(this.task.materialComponents.indexOf(item), 1);
        this.updateSubject.next();
    }

    protected onMaterialComponentSelectionChange(materialComponent: IMaterialComponent, $event): void {
        materialComponent.material = $event;
        this.updateSubject.next();
    }

    protected onComponentChange(): void {
        this.updateSubject.next();
    }

    protected onMaterialCategorySelectionChange(event: IMaterialCategory[]): void {
        if (event == null) {
            delete this.task.materialCategories;
            this.updateSubject.next();
            return;
        }

        this.task.materialCategories = event;
        this.updateSubject.next();
    }

    protected onCssElementSelectionChange(event: ICssElement): void {
        this.task.cssElement = event;
        this.updateSubject.next();
    }

    protected onBuildUpSelectionChange(event: IBuildUp[]): void {
        if (event == null) {
            delete this.task.buildUps;
            this.updateSubject.next();
            return;
        }

        this.task.buildUps = event;
        this.updateSubject.next();
    }

    protected onTaskFinishChange(event: any): void {
        this.task.type = event.currentTarget.checked ? 'FINISH' : 'COMMON';
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ITask>>): void {
        result
            .pipe(
                finalize(() => {
                    this.isSaving = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ITask>) => this.onSaveSuccess()
            );
    }

    private onSaveSuccess(): void {
        this.previousState();
    }

    private fillElements(): void {
        if (this.task.stage == null) {
            this.elements = [];
        } else {
            this.elementService.queryByStageId(this.task.stage.id).subscribe(
                (res: HttpResponse<IElement[]>) => {
                    this.elements = res.body;
                }
            );
        }
    }

    private fillMaterialCategorySelectInputData(): void {
        this.materialCategorySelectInputData = {
            searchBy: 'name',
            indexProperty: 'id',
            titleProperty: 'name',
            displayProperty: 'name',
            itemService: this.materialCategoryService,
            dropdownPosition: 'bottom',
            clearable: true,
            eventEmitter: new Subject<void>(),
            onEnterClickHandler: this.onMaterialCategorySelectInputEnterClick.bind(this)
        };

        if (this.task.materialCategories?.length) {
            this.materialCategorySelectInputData.initValue = this.task.materialCategories.map(mc => mc.name);
            this.materialCategorySelectInputData.initIndex = this.task.materialCategories.map(mc => mc.id);
        }
    }

    private fillCssElementSelectInputData(): void {
        this.cssElementSelectInputData = {
            searchBy: 'name',
            indexProperty: 'id',
            titleProperty: 'name',
            displayProperty: 'name',
            itemService: this.cssElementService,
            dropdownPosition: 'bottom',
            clearable: true,
            eventEmitter: new Subject(),
            onEnterClickHandler: this.onCssElementSelectInputEnterClick.bind(this)
        };

        if (this.task.cssElement != null) {
            this.cssElementSelectInputData.initValue = this.task.cssElement.name;
            this.cssElementSelectInputData.initIndex = this.task.cssElement.id;
        }
    }

    private fillBuildUpSelectInputData(): void {
        this.buildUpSelectInputData = {
            searchBy: 'name',
            indexProperty: 'id',
            titleProperty: 'name',
            displayProperty: 'name',
            itemService: this.buildUpApi,
            dropdownPosition: 'bottom',
            clearable: true,
            eventEmitter: new Subject(),
            onEnterClickHandler: this.onBuildUpSelectInputEnterClick.bind(this)
        };

        if (this.task.buildUps?.length) {
            this.buildUpSelectInputData.initValue = this.task.buildUps.map(bu => bu.name);
            this.buildUpSelectInputData.initIndex = this.task.buildUps.map(bu => bu.id);
        }
    }

    private onMaterialCategorySelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length <= 0) {
            return;
        }

        this.addMaterialCategoryModalService.open(currentTextValue).result.then(
            (result: IMaterialCategory) => {
                if (result == null) {
                    return;
                }
                this.materialCategorySelectInputData.initValue = [...this.materialCategorySelectInputData.initValue, result.name];
                this.materialCategorySelectInputData.initIndex = [...this.materialCategorySelectInputData.initIndex, result.id];

                this.materialCategorySelectInputData.eventEmitter.next();

                this.onMaterialCategorySelectionChange([result]);
            },
            reason => {
                // This is intentional
            }
        );
    }


    private onCssElementSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length <= 0) {
            return;
        }

        this.addCssElementModalService.open(currentTextValue).result.then(
            (result: ICssElement) => {
                if (result == null) {
                    return;
                }
                this.cssElementSelectInputData.initValue = result.name;
                this.cssElementSelectInputData.initIndex = result.id;
                this.cssElementSelectInputData.displayValue = result.name;

                this.cssElementSelectInputData.eventEmitter.next();

                this.onCssElementSelectionChange(result);
            },
            reason => {
                // This is intentional
            }
        );
    }

    private onBuildUpSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length <= 0) {
            return;
        }

        this.addBuildUpModalService.open(currentTextValue).result.then(
            (result: IBuildUp) => {
                if (result == null) {
                    return;
                }

                this.buildUpSelectInputData.initValue = [...this.buildUpSelectInputData.initValue, result.name];
                this.buildUpSelectInputData.initIndex = [...this.buildUpSelectInputData.initIndex, result.id];

                this.buildUpSelectInputData.eventEmitter.next();

                this.onBuildUpSelectionChange([result]);
            },
            reason => {
                // This is intentional
            }
        );
    }
}
