export type ShowSubStagesMode = 'show-in-use' | 'hide-all' | 'display-all';

export interface ShowSubStagesModeInfo {
    id: ShowSubStagesMode;
    extendedLabel: string;
    label: string;
    icon: string;
}

export const SHOW_SUB_STAGES_MODES: ShowSubStagesModeInfo[] = [
    { id: 'show-in-use', label: 'In use', extendedLabel: 'Templates - In use', icon: 'format_align_center' },
    { id: 'hide-all', label: 'Off', extendedLabel: 'Templates - Off', icon: 'drag_handle' },
    { id: 'display-all', label: 'On', extendedLabel: 'Templates - On', icon: 'format_align_justify' }
];
