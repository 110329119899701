<div class="container-fluid">
    <div *ngIf="!templateSelectionView"
         class="main-fixed-container">
        <form (ngSubmit)="ngSubmitImpl()"
              [formGroup]="form"
              autocomplete="off"
              class="card"
              name="form"
              novalidate>
            <button #submitFormButton
                    id="submitFormButton"
                    type="submit">
            </button>

            <div class="d-none d-md-block text-right m-b-20">
                <button (click)="submitFormButton.click()"
                        class="btn btn-primary btn-border-radius waves-effect next"
                        ngbTooltip="Next"
                        type="button">
                    Next
                    <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                </button>
            </div>

            <div class="flex justify-content-center">
                <div class="logic-container">
                    <div class="w-full flex justify-content-center m-b-20"
                         *ngIf="addon !== undefined">
                        <h1 *ngIf="!addon; else addonTitle">
                            Create Project
                        </h1>
                        <ng-template #addonTitle>
                            <div class="flex flex-column gap-1 align-items-center justify-content-center">
                                <h1>
                                    {{ addon.name }}
                                </h1>
                                <div class="font-14 font-weight-bold">
                                    Please fill out the details below
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div class="flex flex-row justify-content-between flex-wrap gap-100-column gap-20-row m-t-20">
                        <div class="flex-1">
                            <div class="form-group">
                                <div class="form-line">
                                    <ng-select appearance="outline"
                                               formControlName="postcode"
                                               class="bp-select remove-underlines remove-arrow-wrapper fs-14"
                                               [items]="addressSuggestions$ | async"
                                               [clearable]="false"
                                               bindLabel="postcode"
                                               [addTag]="true"
                                               [multiple]="false"
                                               [hideSelected]="false"
                                               [trackByFn]="trackAddressByPostcodeByFn"
                                               [minTermLength]="1"
                                               [loading]="addressSuggestionsLoading"
                                               notFoundText="No postcodes found"
                                               typeToSearchText="Enter Postcode to search"
                                               placeholder="Postcode *"
                                               [typeahead]="postcodeInput$"
                                               (change)="onAddressByPostcodeChange($event)">
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{ item.formattedAddress }}
                                        </ng-template>
                                        <ng-template ng-tag-tmp let-search="searchTerm">
                                            <b>Use free text postcode</b>: {{ search }}
                                        </ng-template>
                                    </ng-select>
                                    <div *ngIf="(submitted || postcode.dirty) && postcode.invalid">
                                        <label *ngIf="postcode.errors.required"
                                               class="error">
                                            This field is required.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || address.dirty || address.touched) && address.invalid }"
                                        class="af-input"
                                        formControlName="address"
                                        name="address"
                                        placeholder="First Line Address*"
                                        autocomplete="off"
                                        type="text">
                                    <div *ngIf="(submitted || address.dirty) && address.invalid">
                                        <label *ngIf="address.errors.required"
                                               class="error">
                                            This field is required.
                                        </label>
                                        <label *ngIf="address.errors.whitespace"
                                               class="error">
                                            Please enter valid data.
                                        </label>
                                        <label *ngIf="address.errors.alreadyExists"
                                               class="error">
                                            Project with such address already exists.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group"
                                 *ngIf="!isAdmin">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || addressSecondLine.dirty || addressSecondLine.touched) && addressSecondLine.invalid }"
                                        class="af-input"
                                        formControlName="addressSecondLine"
                                        name="addressSecondLine"
                                        placeholder="Second Line Address"
                                        autocomplete="off"
                                        type="text">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || city.dirty || city.touched) && city.invalid }"
                                        class="af-input"
                                        formControlName="city"
                                        name="city"
                                        placeholder="City"
                                        autocomplete="off"
                                        type="text">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line"
                                     [ngClass]="{'ng-invalid': project.region == null}">
                                    <bp-select-input
                                        #regionSelectInput
                                        *ngIf="regionsInputData"
                                        (onSelectionChange)="onRegionSelectionChange($event)"
                                        [data]="regionsInputData"
                                        [placeholder]="'Region*'"
                                        [removeUnderlines]="true"
                                        [additionalClasses]="'fs-14'"
                                        class="region-select-id">
                                    </bp-select-input>
                                    <div *ngIf="submitted && this.project.region == null">
                                        <label class="error">
                                            This field is required.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="addon">
                                <div class="row m-t-30">
                                    <div class="col-sm-4">
                                        <div class="date-container">
                                            <label class="gray-text form-control-label"
                                                   for="field_startDate">
                                                Project Start Date
                                            </label>
                                            <div>
                                                <bp-date-picker
                                                    id="field_startDate"
                                                    [date]="project.startDate"
                                                    (onDateChanged)="onStartDateSelect($event)">
                                                </bp-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="date-container">
                                            <label class="gray-text form-control-label"
                                                   for="field_TenderDeadlineDate">
                                                Tender Deadline Date
                                            </label>
                                            <div>
                                                <bp-date-picker
                                                    id="field_TenderDeadlineDate"
                                                    [date]="project.tenderDeadline"
                                                    (onDateChanged)="onTenderDeadlineDateSelect($event)">
                                                </bp-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="date-container">
                                            <label class="gray-text form-control-label"
                                                   for="field_TenderDecisionDate">
                                                Tender Decision Date
                                            </label>
                                            <div>
                                                <bp-date-picker
                                                    id="field_TenderDecisionDate"
                                                    [date]="project.tenderDecision"
                                                    (onDateChanged)="onTenderDecisionDateSelect($event)">
                                                </bp-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || tenderNotes.dirty || tenderNotes.touched) && tenderNotes.invalid }"
                                    class="af-input tender-notes"
                                    formControlName="tenderNotes"
                                    name="tenderNotes"
                                    autocomplete="off"
                                    placeholder="Summary of Works">
                                </textarea>
                                        <div *ngIf="(submitted || tenderNotes.dirty) && tenderNotes.invalid">
                                            <label *ngIf="tenderNotes.errors.maxlength"
                                                   class="error">
                                                Maximum length
                                                is {{ tenderNotes.errors.maxlength.requiredLength }}
                                                symbols
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || attachmentsUrl.dirty || attachmentsUrl.touched) && attachmentsUrl.invalid }"
                                    class="af-input attachmentsUrl"
                                    formControlName="attachmentsUrl"
                                    name="attachmentsUrl"
                                    autocomplete="off"
                                    placeholder="Attachments URL (Dropbox, Google Drive or similar)">
                                </textarea>
                                        <div *ngIf="(submitted || attachmentsUrl.dirty) && attachmentsUrl.invalid">
                                            <label *ngIf="attachmentsUrl.errors.maxlength"
                                                   class="error">
                                                Maximum length
                                                is {{ attachmentsUrl.errors.maxlength.requiredLength }}
                                                symbols
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="form-line">
                                        <div class="gray-text">
                                            Shared attachments (Floorplans, sketches, planning and developments drawings
                                            or
                                            similar)
                                        </div>
                                        <small>
                                            Supports: Jpeg, png, bmp, pdf, svg, csv. 5mb limit per file upload.
                                        </small>
                                        <bp-project-attachments-fe-only
                                            class="m-t-20"
                                            id="project-attachment-fe-only"
                                            buttonLabel="Upload Document"
                                            [accept]="'application/pdf'"
                                            (onChanged)="files = $event">
                                        </bp-project-attachments-fe-only>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="form-group"
                                 *ngIf="isAdmin">
                                <div class="form-line">
                                    <input
                                        class="af-input"
                                        formControlName="templateName"
                                        name="templateName"
                                        placeholder="Property template name"
                                        autocomplete="off"
                                        type="text">
                                </div>
                            </div>

                            <ng-container *ngIf="isAdmin">
                                <div class="form-group">
                                    <div class="form-line">
                                        <label class="form-control-label"
                                               for="field_tags_for_existed_project">
                                            Tags
                                        </label>
                                        <ng-select [(ngModel)]="project.tags"
                                                   [ngModelOptions]="{standalone: true}"
                                                   [items]="tags"
                                                   [hideSelected]="true"
                                                   [virtualScroll]="true"
                                                   multiple="true"
                                                   bindLabel="name"
                                                   placeholder="-- start typing to find tag --"
                                                   id="field_tags_for_existed_project">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || notes.dirty || notes.touched) && notes.invalid }"
                                    [ngStyle]="{'height': isAdmin ? '100px' : '160px'}"
                                    class="af-input notes"
                                    formControlName="notes"
                                    name="notes"
                                    placeholder="Enter tender notes">
                                </textarea>
                                        <div *ngIf="(submitted || notes.dirty) && notes.invalid">
                                            <label *ngIf="notes.errors.maxlength"
                                                   class="error">
                                                Maximum length is {{ notes.errors.maxlength.requiredLength }}
                                                symbols
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="flex-1 flex flex-column gap-20"
                             style="min-width: 300px">
                            <div class="text-center">
                                <bp-image-uploader
                                    *ngIf="isAdmin"
                                    [disabled]="inProcess()"
                                    [initialImageUrl]="project.icon">
                                </bp-image-uploader>

                                <bp-project-image
                                    *ngIf="!isAdmin"
                                    [disabled]="inProcess()"
                                    [project]="project"
                                    [postcode]="postcode.value">
                                </bp-project-image>
                            </div>

                            <submit-for-analysis-form *ngIf="addon?.name === 'Plan Measurement'"
                                                      [project]="project">
                            </submit-for-analysis-form>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="d-none d-md-block next-button-container text-right">
            <button (click)="submitFormButton.click()"
                    class="btn btn-primary btn-border-radius waves-effect next"
                    ngbTooltip="Next"
                    type="button">
                Next
                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>
        </div>

        <div class="fixed-footer-mobile">
            <div class="flex-between-container align-items-center">
                <div class="flex-1 text-right">
                    <div class="text-right m-b-40">
                        <button (click)="submitFormButton.click()"
                                class="btn btn-primary btn-border-radius waves-effect next"
                                ngbTooltip="Next"
                                type="button">
                            Next
                            <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="templateSelectionView">
        <bp-template-wizard
            [project]="project"
            [addonId]="addon?.id"
            [files]="files">
        </bp-template-wizard>
    </div>
</div>
