import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectTimelineService } from 'app/shared/dataservices/project-timeline.service';
import { HttpResponse } from '@angular/common/http';
import { IProjectTimeline, IProjectTimelineStage } from 'app/shared/model/project-time-line.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { lastValueFrom, Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';

@Component({
    selector: 'bp-dashboard-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['timeline.scss']
})
export class DashboardTimelineComponent implements OnInit, OnDestroy {
    @BlockUI()
    protected blockUI: NgBlockUI;

    protected tradespeopleControl = new FormControl(null);
    protected tradespeopleControlSub = Subscription.EMPTY;

    protected overflowXClass = 'overflow-x-hidden';

    protected WIDTH = 25;
    protected HEIGHT = 40;

    protected weeks: number[] = [];

    protected timelineForOneTradespeople: IProjectTimeline | null = null;
    protected timeline: IProjectTimeline | null = null;

    protected get maxHeight(): number {
        return this.timeline.stages?.length * this.HEIGHT;
    }

    constructor(
        public dashboardStore: DashboardStore,
        private projectTimelineService: ProjectTimelineService
    ) {
    }

    ngOnInit(): void {
        this.updateTimeline().then(() => {
            this.tradespeopleControlSub = this.tradespeopleControl.valueChanges
                .pipe(debounceTime(700), distinctUntilChanged())
                .subscribe((value: number) => {
                    if (value == this.timeline.tradespeople) {
                        return;
                    }
                    this.blockUI.start('Please wait..');
                    this.timeline.tradespeople = value;
                    this.projectTimelineService.setTradespeople(this.dashboardStore.project.id, value).subscribe(() => {
                        this.timeline = this.getTimelineForTradespeople(this.timelineForOneTradespeople, value);
                        this.updateTimeline().finally(() => {
                            this.blockUI.stop();
                        })
                    });
                });
        });
    }

    ngOnDestroy(): void {
        this.tradespeopleControlSub.unsubscribe();
    }

    protected getLeft(index: number): number {
        if (index === 0 || index === 1) {
            return 0;
        }

        let left = 0;
        for (let i = 1; i < index; i++) {
            left += this.timeline.stages[i].days / 7;
        }

        return left * this.WIDTH;
    }

    protected getTop(index: number): number {
        return this.HEIGHT * index;
    }

    private getWeeks(): number[] {
        const timelineAreaWidth = document.getElementById('timeline-area')?.clientWidth || 0;
        this.WIDTH = Math.ceil(timelineAreaWidth / (this.timeline.totalWeeks + 1.5));
        if (this.WIDTH === 0) {
            return [];
        }

        const arrayData = Math.ceil(timelineAreaWidth / this.WIDTH);
        this.overflowXClass = arrayData !== this.timeline.totalWeeks ? 'overflow-x-hidden' : 'overflow-x-auto';
        return Array.from(Array(arrayData).keys());
    }

    private async updateTimeline(): Promise<void> {
        const res = await lastValueFrom(this.projectTimelineService.query(this.dashboardStore.project.id));
        this.timelineForOneTradespeople = res.body;
        this.timeline = this.getTimelineForTradespeople(this.timelineForOneTradespeople, res.body.tradespeople);
        this.tradespeopleControl.setValue(res.body.tradespeople);
        setTimeout(() => {
            this.weeks = this.getWeeks();
        });
    }

    private getTimelineForTradespeople(timeline: IProjectTimeline, tradespeople: number): IProjectTimeline {
        const result = JSON.parse(JSON.stringify(timeline)); // deep copy
        result.totalWeeks = +(result.totalWeeks / tradespeople).toFixed(2);
        result.tradespeople = tradespeople;
        result.stages.forEach((stage: IProjectTimelineStage) => {
            stage.days = +(stage.days / tradespeople).toFixed(2);
            stage.hours = +(8 * stage.days).toFixed(2);
        })
        return result;
    }
}
