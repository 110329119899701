import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddGroupOfScheduleTasksModalComponent } from './add-group-of-schedule-tasks-modal.component';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';

@Injectable({ providedIn: 'root' })
export class AddGroupOfScheduleTasksModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(project: IProject, scheduleAreas: IScheduleArea[], initialScheduleArea: IScheduleArea, initialSearchValue?: string): NgbModalRef {
        this.ngbModalRef = this.modalService.open(AddGroupOfScheduleTasksModalComponent as Component, {
            windowClass: 'bp-modal fill-width',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;
        this.ngbModalRef.componentInstance.scheduleAreas = scheduleAreas;

        if (initialScheduleArea) {
            this.ngbModalRef.componentInstance.initialScheduleArea = initialScheduleArea;
        }

        if (initialSearchValue?.length) {
            this.ngbModalRef.componentInstance.initialSearchValue = initialSearchValue;
        }

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
