<div class="spinner"
     title="In progress.. Please wait.."
     *ngIf="timeline == null; else timelineDiv">
</div>

<ng-template #timelineDiv>
    <div class="timeline">
        <div class="timeline__header">
            <div class="flex-1">Average no. of tradespeople:</div>
            <input [formControl]="tradespeopleControl"
                   class="timeline__average-no-of-tradespeople"
                   type="text"
                   [maxlength]="5"
                   oninput="this.value=this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"/>
        </div>

        <div class="flex flex-row timeline__area m-t-20 w-full"
             style="max-width: 100%;">
            <div class="flex flex-column">
                <div class="timeline__stage_header"
                     [style.height.px]="HEIGHT"
                     [style.min-height.px]="HEIGHT">
                    <span>Total Duration</span> <span>{{ timeline.totalWeeks }} wks</span>
                </div>
                <div class="timeline__stage_body overflow-y-auto">
                    <div *ngFor="let stage of timeline.stages"
                         class="timeline__stage"
                         [style.height.px]="HEIGHT"
                         [style.min-height.px]="HEIGHT">
                        {{ stage.stage }}
                    </div>
                </div>
            </div>

            <div class="{{ overflowXClass }} overflow-y-auto"
                 id="timeline-area">
                <div class="flex flex-row">
                    <div *ngFor="let week of weeks"
                         class="timeline__week-number"
                         [style.min-width.px]="WIDTH"
                         [style.height.px]="HEIGHT">
                        {{ week + 1 }}
                    </div>
                </div>

                <div class="position-relative overflow-y-hidden"
                     [style.width.px]="weeks?.length * WIDTH"
                     [style.height.px]="timeline.stages?.length * HEIGHT">
                    <div *ngFor="let week of weeks; let index = index"
                         [ngClass]="{'gray': 0 === index % 2}"
                         class="timeline__gray-line"
                         [style.top.px]="0"
                         [style.min-width.px]="WIDTH"
                         [style.left.px]="week * WIDTH"
                         [style.height.px]="timeline.stages?.length * HEIGHT">
                    </div>

                    <div *ngFor="let stage of timeline.stages; let index = index"
                         class="timeline__rect"
                         [style.left.px]="getLeft(index)"
                         [style.top.px]="getTop(index)"
                         [style.height.px]="HEIGHT / 2"
                         [style.width.px]="timeline.stages[index].days / 7 * WIDTH">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
