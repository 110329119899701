import { Component, Input } from '@angular/core';
import { IElement } from 'app/shared/model/element.model';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';

@Component({
    selector: 'bp-schedule-element-for-filter-by-area-grouping',
    templateUrl: './element.component.html',
    styleUrls: [
        '../../root-container/element.scss',
        '../../../schedule-common.scss'
    ]
})
export class ElementForFilterByAreaGroupingComponent {
    @Input() stage: IStage;
    @Input() element: IElement;
    @Input() scheduleArea: IScheduleArea;

    constructor(
        public scheduleService: ScheduleService
    ) {
    }

    protected get elementTotal(): number {
        return this.filteredByAreaAndNotMarkedAsDeletedScheduleTasks()
            .reduce((total, task) => task.taskTotal.total + total, 0);
    }

    protected filteredByAreaAndNotMarkedAsDeletedScheduleTasks(): IScheduleTask[] {
        return _.filter(this.element.scheduleTasks, scheduleTask => {
            if (this.scheduleArea != null && scheduleTask.scheduleAreaId !== this.scheduleArea.id) {
                return false;
            }
            return !scheduleTask.markedAsDeleted;
        });
    }
}
