<div class="container-fluid text-center"
     *ngIf="!comparisonStore.filterState">
    Please wait...
</div>

<div class="container-fluid"
     *ngIf="comparisonStore.filterState">
    <div class="block-header m-b-10">
        <div class="row">
            <div class="col-4">
                <a [routerLink]="['../../../quotes', comparisonStore.project.id]"
                   class="back-link">
                    <i class="material-icons">arrow_back</i> Back to quotes
                </a>
            </div>

            <div class="col-4 text-center">
                <h1>
                    Quote Comparison
                </h1>
            </div>

            <div class="col-4 text-right">
                <button [disabled]="comparisonService.inProcess()"
                        [popper]="bpExportSelector"
                        [popperAppendTo]="'body'"
                        [popperTrigger]="'click'"
                        [popperPlacement]="'bottom'"
                        [popperPreventOverflow]="true"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        [popperApplyClass]="'bp-popper'"
                        class="btn btn-secondary btn-border-radius m-r-5 export"
                        ngbTooltip="Export"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            file_download
                        </i>
                        Export

                        <i class="material-icons cursor-pointer">
                            expand_more
                        </i>
                    </div>
                </button>

                <popper-content #bpExportSelector>
                    <bp-export-selector
                        (onChanged)="onExportTypeSelected($event)">
                    </bp-export-selector>
                </popper-content>


                <button (click)="comparisonService.toggleExpand()"
                        [disabled]="comparisonService.inProcess()"
                        *ngIf="comparisonStore.filterState.groupBy === 'area' || comparisonStore.filterState.groupBy === 'stage'"
                        class="btn btn-secondary btn-border-radius expand-collapse m-r-5"
                        ngbTooltip="{{ comparisonStore.expandedAll ? 'Collapse' : 'Expand'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <ng-container *ngIf="comparisonStore.expandedAll">
                            <i class="material-icons md-24">
                                fullscreen_exit
                            </i>
                            Collapse
                        </ng-container>

                        <ng-container *ngIf="!comparisonStore.expandedAll">
                            <i class="material-icons md-24">
                                fullscreen
                            </i>
                            Expand
                        </ng-container>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div class="row"
         *ngIf="comparisonStore.inited">
        <div class="col-md-12">

            <div class="af-row-container">
                <div class="row">
                    <div class="col-md-8">
                        <bp-main-view-filter
                            class="m-b-10"
                            (onChanged)="onMainViewFilterChangedFunc($event)"
                            [initialFilterState]="comparisonStore.filterState"
                            [areaItems]="comparisonStore.scheduleAreaItems"
                            [stageItems]="comparisonStore.stageItems"
                            [cssElementItems]="comparisonStore.cssElementItems">
                        </bp-main-view-filter>
                    </div>

                    <div class="col-md-4">
                        <div class="right">
                            <bp-select-quoters
                                (onChanged)="onSelectedQuotersChanged($event)"
                                [quoters]="comparisonStore.quoters">
                            </bp-select-quoters>
                        </div>
                    </div>
                </div>
            </div>

            <bp-comparison-root-container>
            </bp-comparison-root-container>
        </div>
    </div>

    <div class="row"
         *ngIf="!comparisonStore.inited || comparisonService.inProcess()">
        <div class="col-md-12 text-center">
            <strong>
                Please wait..
            </strong>
        </div>
    </div>
</div>
