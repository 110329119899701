<div class="container-fluid areas">
    <div class="areas__top-header-container flex-between-container flex-wrap align-items-start">
        <div class="flex-1">
            <div class="areas__area-view-mode-container flex gap-10">
                <button (click)="setMode('Width_Depth_Height')"
                        [disabled]="inProcess()"
                        [ngClass]="{'selected' : mode === 'Width_Depth_Height'}"
                        [placement]="'bottom'"
                        class="btn btn-secondary btn-border-radius-5"
                        ngbTooltip=" Width / Depth / Height">
                    W / D / H
                </button>

                <button (click)="setMode('WallArea_FloorArea_Perimeter')"
                        [disabled]="inProcess()"
                        [ngClass]="{'selected': mode === 'WallArea_FloorArea_Perimeter'}"
                        [placement]="'bottom'"
                        class="btn btn-secondary btn-border-radius-5"
                        ngbTooltip="Area / Perimeter">
                    Area / Perimeter
                </button>
            </div>
        </div>

        <div class="flex-1 text-center areas__abs-header-container">
            <h1 class="m-b-0">
                Area Details
            </h1>
        </div>

        <div class="flex-1 flex flex-row justify-content-end gap-10">
            <button (click)="createAreasFromKreo()"
                    *ngIf="getProxyAdmin() && hasKreoProjectId"
                    [disabled]="inProcess()"
                    [placement]="'bottom'"
                    class="btn btn-secondary btn-border-radius d-none d-md-inline"
                    ngbTooltip="Create areas from Kreo">
                Create areas from Kreo
            </button>

            <button (click)="pullMeasurementsFromKreo()"
                    *ngIf="getProxyAdmin() && hasKreoProjectId"
                    [disabled]="inProcess()"
                    [placement]="'bottom'"
                    class="btn btn-secondary btn-border-radius d-none d-md-inline"
                    ngbTooltip="Pull measurements from Kreo">
                Pull measurements from Kreo
            </button>

            <button (click)="onAddTemplateClick()"
                    [disabled]="inProcess()"
                    [placement]="'bottom'"
                    class="btn btn-secondary btn-border-radius d-none d-md-inline"
                    ngbTooltip="Add Area">
                Add Area
            </button>

            <button (click)="onAddTemplateClick()"
                    [disabled]="inProcess()"
                    [placement]="'bottom'"
                    class="btn btn-primary btn-border-radius waves-effect d-md-none"
                    ngbTooltip="Add Area"
                    type="button">
                <i class="material-icons md-18">add</i>
            </button>

            <button (click)="onUploadAndAutoCalculateClick()"
                    [disabled]="inProcess()"
                    [ngbTooltip]="projectAttachments?.length ? 'Edit submitted floorplans' : 'Upload & Auto-Calculate'"
                    [placement]="'bottom'"
                    class="btn btn-secondary btn-border-radius d-none d-md-inline">
                {{ projectAttachments?.length ? 'Edit submitted floorplans' : 'Upload & Auto-Calculate' }}
            </button>

            <button (click)="onUploadAndAutoCalculateClick()"
                    [disabled]="inProcess()"
                    class="btn btn-primary btn-border-radius waves-effect d-md-none"
                    ngbTooltip="Upload & Auto-Calculate"
                    type="button">
                <i class="material-icons md-18">upload</i>
            </button>

            <div class="d-none d-md-flex flex-column gap-10">
                <button (click)="onActionClick()"
                        [disabled]="invalid() || inProcess()"
                        class="btn btn-primary btn-border-radius waves-effect save-and-exit"
                        [ngbTooltip]="actionButtonText"
                        type="button">
                    {{ actionButtonText }}
                </button>
            </div>

            <button (click)="onActionClick()"
                    [disabled]="invalid() || inProcess()"
                    [ngbTooltip]="actionButtonText"
                    [placement]="'bottom'"
                    class="btn btn-primary btn-border-radius waves-effect save-and-exit d-md-none"
                    type="button">
                <i class="material-icons md-18">exit_to_app</i>
            </button>
        </div>
    </div>

    <div class="flex items-center justify-content-end autosave-line m-t-10 d-none d-md-flex">
        <ng-container *ngIf="!inProcess(); else isSavingContainer">
            <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
            Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
        </ng-container>
        <ng-template #isSavingContainer>
            <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
            Saving...
        </ng-template>
    </div>

    <div class="m-b-5 m-t-20 d-md-none">
        <div class="flex items-center justify-content-start autosave-line">
            <ng-container *ngIf="!inProcess(); else isSavingContainer">
                <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
            </ng-container>
            <ng-template #isSavingContainer>
                <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                Saving...
            </ng-template>
        </div>
    </div>

    <div class="m-b-5 m-t-20">
        <form #f="ngForm"
              name="form"
              novalidate>
            <div class="flex flex-column gap-100">
                <div *ngFor="let key of Object.keys(groupedActualScheduleAreas)">
                    <div *ngIf="showTableHeaderForTag(key)"
                         class="areas__header-container flex-between-container items-center"
                         style="column-gap: 15px">
                        <div class="area-name" style="flex: 3">
                            <input [formControl]="tagControls[key]"
                                   class="af-input font-weight-bold"
                                   style="width: 50%;"
                                   id="tag_{{key}};"
                                   name="tag_{{key}}"
                                   type="text">
                        </div>
                        <ng-container *ngIf="mode === 'Width_Depth_Height'">
                            <div style="flex: 1">
                                <label class="form-label">
                                    Width
                                </label>
                            </div>
                            <div style="flex: 1">
                                <label class="form-label">
                                    Depth
                                </label>
                            </div>
                            <div style="flex: 1">
                                <label class="form-label">
                                    Height
                                </label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="mode === 'WallArea_FloorArea_Perimeter'">
                            <div style="flex: 1">
                                <label class="form-label">
                                    Wall Area
                                </label>
                            </div>
                            <div style="flex: 1">
                                <label class="form-label">
                                    Floor Area
                                </label>
                            </div>
                            <div style="flex: 1">
                                <label class="form-label">
                                    Ceiling Area
                                </label>
                            </div>
                            <div style="flex: 1">
                                <label class="form-label">
                                    Perimeter
                                </label>
                            </div>
                        </ng-container>
                        <div class="no-border areas__controls">
                            <div class="area-total-for-tag d-none d-md-inline">
                                <div class="gia-container">
                                    <div class="gia">
                                        {{ groupedActualScheduleAreas[key][0]?.tag?.name.toLowerCase().includes('shell') ? 'GEA' : 'GIFA' }} {{ getTotalAreaForTag(key) | number : '1.2-2' }}
                                        <span>m<sup>2</sup></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="body-container">
                        <div [attr.draggable]="!scheduleArea._empty"
                             (dragstart)="onAreaDragStart($event, scheduleArea, index, key)"
                             (dragover)="onAreaDragOver($event, scheduleArea)"
                             (drop)="onAreaDrop($event, scheduleArea, index, key)"
                             class="draggable position-relative"
                             *ngFor="let scheduleArea of groupedActualScheduleAreas[key]; let index = index">
                            <i class="material-icons md-18 drag" *ngIf="!scheduleArea._empty">drag_indicator</i>
                            <bp-schedule-area-row
                                (onCopy)="onCopyScheduleArea(scheduleArea)"
                                (onRemove)="onRemoveScheduleArea(scheduleArea)"
                                (onStartToFill)="onStartToFillScheduleArea(scheduleArea)"
                                (onUpdate)="onUpdate()"
                                [mode]="mode"
                                [project]="project"
                                [scheduleArea]="scheduleArea"
                                [submitted]="submitted">
                            </bp-schedule-area-row>
                        </div>
                    </div>
                    <div
                        *ngIf="groupedActualScheduleAreas[key].length > 0 && !groupedActualScheduleAreas[key][0]._empty"
                        class="area-total-for-tag w-full m-t-20 text-right d-md-none">
                        <div class="gia-container">
                            <div class="gia">
                                {{ groupedActualScheduleAreas[key][0]?.tag?.name.toLowerCase().includes('shell') ? 'GEA' : 'GIFA' }} {{ getTotalAreaForTag(key) | number : '1.2-2' }}
                                <span>m<sup>2</sup></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button #submitFormButton
                    id="submitFormButton"
                    type="submit">
            </button>
        </form>
    </div>

    <div class="d-none d-md-inline">
        <div class="flex-between-container align-items-center m-t-100 m-b-50">
            <div class="flex-1">
                <button [routerLink]="['../../project-details', project.id]"
                        class="btn btn-default btn-border-radius waves-effect previous"
                        ngbTooltip="Back to Project Info"
                        type="button">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    Back to Project Info
                </button>
            </div>
            <div class="flex-1 text-right flex flex-row justify-content-end gap-10">
                <button (click)="onActionClick()"
                        [disabled]="invalid() || inProcess()"
                        class="btn btn-primary btn-border-radius waves-effect save-and-exit"
                        [ngbTooltip]="actionButtonText"
                        type="button">
                    {{ actionButtonText }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="fixed-footer-mobile">
    <div class="flex-between-container align-items-center">
        <div class="flex-1">
            <button *ngIf="returnPage === 'schedule'"
                    [routerLink]="['../../schedule', project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous m-r-20"
                    ngbTooltip="Back to Schedule"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back to Schedule
            </button>

            <button *ngIf="returnPage === 'cost_plan'"
                    [routerLink]="['../../dashboard', project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back to Dashboard"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back to Dashboard
            </button>
        </div>

        <div class="flex-1 text-right">
            <div class="flex-1 text-right flex flex-row justify-content-end gap-10">
                <button (click)="onActionClick()"
                        [disabled]="invalid() || inProcess()"
                        class="btn btn-primary btn-border-radius waves-effect save-and-exit"
                        [ngbTooltip]="actionButtonText"
                        type="button">
                    {{ actionButtonText }}
                </button>
            </div>
        </div>
    </div>
</div>
