import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INewTaskRequest, INewTaskRequestCreationDTO, IUpdateTaskRequestDTO } from 'app/shared/model/new-task-request.model';
import { ITask } from 'app/shared/model/task.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

type EntityArrayResponseType = HttpResponse<INewTaskRequest[]>;

@Injectable({ providedIn: 'root' })
export class NewTaskRequestApi {
    public resourceUrl = SERVER_API_URL + 'api/new-task-request';

    constructor(private http: HttpClient) {}

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<INewTaskRequest[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    get(newTaskRequestId: number): Observable<HttpResponse<ITask>> {
        return this.http.get<ITask>(`${this.resourceUrl}/${newTaskRequestId}`, { observe: 'response' });
    }

    newTask(data: INewTaskRequestCreationDTO): Observable<HttpResponse<ITask>> {
        return this.http.post<ITask>(this.resourceUrl, data, { observe: 'response' });
    }

    update(newTaskRequestId: number, data: IUpdateTaskRequestDTO): Observable<HttpResponse<ITask>> {
        return this.http.put<ITask>(`${this.resourceUrl}/${newTaskRequestId}`, data, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    merge(id: number, taskId: number): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.resourceUrl}/${id}/replace/${taskId}`, { observe: 'response' });
    }
}
