import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import {
    getActualAreaIds,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { IProject } from 'app/shared/model/project.model';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { ReviewByQsModalService } from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ExportReportsService, IMainReportData } from 'app/shared/services/export/export-reports.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PopperContent } from 'ngx-popper';
import { ProjectActionsService } from 'app/shared/services/project-actions.service';
import { finalize } from 'rxjs/operators';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { CopyToClipboardService } from 'app/shared/services/copy-to-clipboard';
import { SuccessModalService } from 'app/shared/components/common/success-modal/success-modal.service';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { AccountService } from 'app/core/auth/account.service';

@Component({
    selector: 'bp-dashboard-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['menu.scss']
})
export class DashboardMenuComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @ViewChild('bpExportSelector') bpExportSelector: PopperContent;

    MENU_TOOLTIP_OPEN_DELAY = 100;

    constructor(
        public dashboardService: DashboardService,
        public dashboardStore: DashboardStore,
        protected topMenuStateService: TopMenuStateService,
        private projectApi: ProjectApi,
        private projectActionsService: ProjectActionsService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private reviewByQsModalService: ReviewByQsModalService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private alertService: BpAlertService,
        private exportReportsService: ExportReportsService,
        private freemiumModalService: FreemiumModalService,
        private _accountService: AccountService,
        private _copyToClipboardService: CopyToClipboardService,
        private _successModalService: SuccessModalService,
        private _userReportModalService: UserReportModalService) {
    }

    ngOnInit(): void {
    }

    protected getVisibleExportItems(): ExportType[] {
        const result: ExportType[] = ['pdf'];
        if (!this._accountService.isHomeowner()) {
            result.push('csv');
            result.push('docx');
        }
        return result;
    }

    protected onMainViewFilterChangedFunc(filterState: IMainViewFilterState): void {
        this.dashboardStore.filterState = filterState;
        this.mainFilterInitialStateStorageService.store(this.dashboardStore.project.id, 'project_overview_scheduler', filterState);
    }

    protected onReviewByQSClick(): void {
        this.reviewByQsModalService.open(this.dashboardStore.project).result.finally(() => {
            this.dashboardService.reloadProject();
        });
    }

    protected onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();

        this.freemiumModalService.verify('export').then((res) => {
            if (res) {
                this.exportAs(exportType).then((res2) => {
                    if (res2) {
                        this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
                    }
                });
            }
        });
    }

    protected onShowAverageRatesButtonClick(): void {
        this.blockUI.start('Please wait...');
        this.dashboardService.addDefaultQuoter().pipe(finalize(() => {
            this.blockUI.stop();
        })).subscribe(() => {
            window.location.reload();
        })
    }

    protected copyLinkForTheClientToBuffer(): void {
        this.projectApi.getTempLink(this.dashboardStore.project.id).subscribe((tempLink) => {
            this._copyToClipboardService.copyToClipboard(tempLink);

            this._successModalService.open({
                header: `Link successfully copied to clipboard`,
                textHtml: `<div class='strong m-b-10'>
                            <a href='${tempLink}' target='_blank' class="link underlined">${tempLink}</a>
                          </div>`,

            });
        })
    }

    protected onUpdateAllToLatestVersionClick(): void {
        this.blockUI.start('Please wait...');
        this.dashboardService.updateAllToLatestVersion().finally(() => {
            this.blockUI.stop();
        }).then(() => {
            window.location.reload();
        })
    }

    protected copyProject(): void {
        this.projectActionsService.copyProject(this.dashboardStore.project).then((newProject: IProject | null) => {
            if (newProject) {
                this.blockUI.start('Your project has been copied. Redirecting to copied project');
                this.router.navigate(['../../dashboard', newProject.id], { relativeTo: this.activatedRoute }).finally(() => {
                    window.location.reload();
                })
            }
        })
    }

    private exportAs(exportType: ExportType): Promise<boolean> {
        return new Promise(resolve => {
            const actualAreaIds = getActualAreaIds(this.dashboardStore.filterState, this.dashboardStore.scheduleAreaItems);

            const mainReportData: IMainReportData = {
                project: this.dashboardStore.project,
                filteredScheduleAreas: this.dashboardStore.nativeScheduleAreas.filter(scheduleArea => actualAreaIds.indexOf(scheduleArea.id) !== -1),
                filterState: this.dashboardStore.filterState,
                scheduleAreaItems: this.dashboardStore.scheduleAreaItems,
                stageItems: this.dashboardStore.stageItems,
                cssElementItems: this.dashboardStore.cssElementItems,
                buildUpItems: []
            }

            switch (exportType) {
                case 'csv':
                    this._userReportModalService.open('EXCEL').result.then((res) => {
                        if (res) {
                            this.exportReportsService.exportAsExcelIndividualQuoter(mainReportData, this.dashboardStore.project.defaultQuoter);
                            resolve(res);
                        }
                    })
                    break;
                case 'docx':
                    this._userReportModalService.open('WORD').result.then((res) => {
                        if (res) {
                            this.exportReportsService.exportAsDocxIndividualQuoter(mainReportData, this.dashboardStore.project.defaultQuoter);
                            resolve(res);
                        }
                    })
                    break;
                case 'pdf':
                    if (this._accountService.isHomeowner()) {
                        this.exportReportsService.exportAsPDFIndividualQuoter(mainReportData, this.dashboardStore.project.defaultQuoter);
                        resolve(true);
                        return;

                    }
                    this._userReportModalService.open('PDF').result.then((res) => {
                        if (res) {
                            this.exportReportsService.exportAsPDFIndividualQuoter(mainReportData, this.dashboardStore.project.defaultQuoter);
                            resolve(res);
                        }
                    })
                    break;
            }
        })
    }
}
