import { Component, Input } from '@angular/core';
import { ProjectNavItemType, TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AccountService } from 'app/core/auth/account.service';

interface IProjectNavItem {
    id: number;
    type: ProjectNavItemType;
    title: string;
    visible: boolean;
}

@Component({
    selector: 'bp-project-nav',
    templateUrl: './project-nav.component.html',
    styleUrls: ['project-nav.scss']
})
export class ProjectNavComponent {
    @Input() creationMode = true;

    items: IProjectNavItem[] = [
        {
            id: 1,
            type: 'PROJECT_INFO',
            title: 'Project Info',
            visible: this.accountService.isScheduler() || this.accountService.isQuoter() || this.accountService.isAdmin()
        },
        {
            id: 2,
            type: 'AREAS',
            title: 'Areas',
            visible: this.topMenuStateService.isSchedulerView()
        },
        {
            id: 3,
            type: 'COST_PLAN',
            title: 'Cost Plan',
            visible: this.topMenuStateService.projectViewAs != null ? this.topMenuStateService.projectViewAs === 'VIEW_AS_QUOTER' : this.accountService.isQuoterOnly()
        },
        {
            id: 4,
            type: 'DASHBOARD',
            title: 'Dashboard',
            visible: this.topMenuStateService.isSchedulerView() || this.topMenuStateService.isHomeownerView()
        },
        {
            id: 5,
            type: 'SCOPE',
            title: 'Scope',
            visible: this.accountService.isBeta() && this.topMenuStateService.isSchedulerView()
        },
        {
            id: 6,
            type: 'SCHEDULE',
            title: 'Schedule',
            visible: this.topMenuStateService.isSchedulerView()
        },
        {
            id: 7,
            type: 'QUOTES',
            title: 'Quotes',
            visible: this.topMenuStateService.isSchedulerView() && !this.accountService.isAdmin()
        },
        {
            id: 8,
            type: 'QUOTE',
            title: 'Quote',
            visible: this.topMenuStateService.projectViewAs != null ? this.topMenuStateService.projectViewAs === 'VIEW_AS_QUOTER' : this.accountService.isQuoterOnly()
        },
        {
            id: 9,
            type: 'VALUATIONS',
            title: 'Valuations',
            visible: false
        }
    ];

    get visibleItems(): IProjectNavItem[] {
        return this.items.filter(item => item.visible);
    }

    constructor(public topMenuStateService: TopMenuStateService,
                private accountService: AccountService) {
    }

    public icon(item: IProjectNavItem): IconProp {
        return this.itemChecked(item)
            ? ['fas', 'check-circle']
            : item.type === this.topMenuStateService.currentItem
                ? ['fas', 'circle']
                : ['far', 'circle'];
    }

    public itemActive(item: IProjectNavItem): boolean {
        return item.type === this.topMenuStateService.currentItem;
    }

    public itemChecked(item: IProjectNavItem): boolean {
        return item.id < this.items.find(i => i.type === this.topMenuStateService.currentItem).id;
    }

    public itemDisabled(item: IProjectNavItem): boolean {
        return this.creationMode && !this.itemActive(item) && !this.itemChecked(item);
    }

    public navigate(item: IProjectNavItem) {
        if (this.itemDisabled(item)) {
         return;
        }
        this.topMenuStateService.currentItem = item.type;
        this.topMenuStateService.navigate();
    }
}
