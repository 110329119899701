<div class="modal-header">
    <div class="text-center"
         style="flex: 1">
        <ng-container *ngIf="mode === 'submit'">
            <h2>
                Request QS
            </h2>
            <span class="gray-text">(Standard subscribers and above get 5% off services prices)</span>
        </ng-container>

        <ng-container *ngIf="mode === 'thanks'">
            <h2>
                Thanks for submitting your schedule!
            </h2>
        </ng-container>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="mode === 'submit'">
        <div class="strong m-b-10">
            We recognise that providing quantities can be a risky business and is best left to the experts. As part of our BuildPartner product we offer our qualified and insured in-house QS team to assist you on your projects.
        </div>
        <div class="strong m-b-10">
            The best time to do this is at the end of RIBA Work Stage 3 when the design is fixed and before commencing detailed design (Work Stage 4).
        </div>
        <div class="m-b-30">
            <a [href]="SERVICES_PRICING_URL"
               class="link underlined"
               target="_blank"
               rel="noopener">
                Learn about our service and pricing options here.
            </a>
        </div>

        <div class="m-b-10">
            <select [formControl]="serviceTypeControl"
                    name="service_type"
                    id="service_type"
                    class="form-control">
                <option [ngValue]="null" disabled>Type of service required *</option>
                <option
                    *ngFor="let serviceOption of serviceTypes;"
                    [ngValue]="serviceOption.value">
                    {{ serviceOption.value }} - <span [innerHTML]="serviceOption.descriptionHtml"></span>
                </option>
            </select>
        </div>

        <div class="m-b-10">
            <textarea
                class="af-input additional-comments"
                [formControl]="additionalCommentsControl"
                name="additional-comments"
                autocomplete="off"
                placeholder="Additional comments..."></textarea>
        </div>

        <div class="form-check m-b-30">
            <label class="form-check-label gray-text font-weight-normal"
                   for="have-attached-project-drawings">
                I have attached project drawings in Project Info *
                <input  [formControl]="haveAttachedProjectDrawingsControl"
                       class="form-check-input"
                       id="have-attached-project-drawings"
                       name="have-attached-project-drawings"
                       type="checkbox">
                <span class="form-check-sign"><span class="check"></span></span>
            </label>
        </div>
    </ng-container>

    <ng-container *ngIf="mode === 'thanks'">
        <div class="font-weight-normal m-b-10">
            We'll get back to you within 5 working days via email.
        </div>
        <div class="font-weight-normal m-b-10">
            In the meantime you can chat to us at any time via the chatbot (bottom right) or at {{ MAIL_TO_EMAIL }}
        </div>
    </ng-container>
</div>

<div class="modal-footer m-t-40">
    <div *ngIf="mode === 'submit'"
         class="modal-footer__centered">
        <button [disabled]="!haveAttachedProjectDrawingsControl.value || !serviceTypeControl.value || inProcess"
                (click)="onSubmitForReviewClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect submit-for-review"
                type="button">
            Submit for Review
        </button>

        <div class="text-left font-12">
            Note: Please allow us approximately 5 working days, you will receive an email from our BuildPartner team to discuss your project.
        </div>
    </div>

    <div *ngIf="mode === 'thanks'"
         class="modal-footer__centered ">
        <button [disabled]="inProcess"
                (click)="onOkClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect submit-for-review"
                type="button">
            OK
        </button>
    </div>
</div>

