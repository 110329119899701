import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IProject } from 'app/shared/model/project.model';
import { ProjectStatusUpdaterService } from 'app/shared/services/project-status-updater.service';
import { CurrentMenuItemStorageService } from 'app/shared/services/current-menu-item-storage.service';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import {
    EstimatorsWorkingOnProjectModalService
} from 'app/shared/components/projects/estimators-working-on-project-modal/estimators-working-on-project-modal.service';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';

export type ProjectNavItemType =
    'PROJECT_INFO'
    | 'AREAS'
    | 'COST_PLAN'
    | 'DASHBOARD'
    | 'SCOPE'
    | 'SCHEDULE'
    | 'QUOTE'
    | 'QUOTES'
    | 'VALUATIONS'

export type ProjectViewAs = 'VIEW_AS_SCHEDULER' | 'VIEW_AS_QUOTER';

@Injectable({ providedIn: 'root' })
export class TopMenuStateService {
    private _currentItemSubject = new BehaviorSubject<ProjectNavItemType>(null);
    public readonly currentItem$ = this._currentItemSubject.asObservable();

    private _projectSubject = new BehaviorSubject<IProject>(null);

    constructor(private _router: Router,
                private _authServerProvider: AuthServerProvider,
                private _accountService: AccountService,
                private _projectStatusUpdaterService: ProjectStatusUpdaterService,
                private _estimatorsWorkingOnProjectModalService: EstimatorsWorkingOnProjectModalService,
                private _currentMenuItemStorageService: CurrentMenuItemStorageService) {
    }

    get currentItem(): ProjectNavItemType | null {
        return this._currentItemSubject.value;
    }

    set currentItem(value: ProjectNavItemType | null) {
        if (this.project?.id != null && value) {
            this._currentMenuItemStorageService.store(this.project.id as number, value);
        }
        this._currentItemSubject.next(value);
    }

    get project(): IProject {
        return this._projectSubject.value;
    }

    set project(value: IProject) {
        this.currentItem = <ProjectNavItemType>this._currentMenuItemStorageService.retrieve(value?.id);
        this._projectSubject.next(value);
    }

    get projectViewAs(): ProjectViewAs | null {
        if (!this.project) {
            return null;
        }

        if (this.project.currentUserRelation?.length === 1 && this.project.currentUserRelation?.includes('ROLE_QUOTER')) {
            return 'VIEW_AS_QUOTER';
        } else if (this.project.currentUserRelation?.includes('ROLE_SCHEDULER')) {
            return 'VIEW_AS_SCHEDULER';
        }

        return null;
    }


    public shouldBeShown(): boolean {
        return this.currentItem != null;
    }

    public updateCurrentItem(): void {
        const url = this._router.url.toLowerCase() || '';

        if (
            url.includes('scheduler/new-project') ||
            url.includes('scheduler/project-details') ||
            url.includes('scheduler/team') ||
            url.includes('quoter/project-details')
        ) {
            this.currentItem = 'PROJECT_INFO';
            return;
        }

        if (
            url.includes('scheduler/edit-schedule-areas')
        ) {
            this.currentItem = 'AREAS';
            return;
        }

        if (url.includes('scheduler/schedule')) {
            this.currentItem = 'SCHEDULE';
            this._projectStatusUpdaterService.updateIfNeeded(this.project, 'Schedule').subscribe();
            return;
        }

        if (url.includes('scheduler/scope')) {
            this.currentItem = 'SCOPE';
            return;
        }

        if (url.includes('scheduler/quotes')
            || url.includes('scheduler/comparison')
            || url.includes('scheduler/individual-quote')
            || (this.projectViewAs === 'VIEW_AS_SCHEDULER' && url.includes('quoter/quote'))) {
            this.currentItem = 'QUOTES';
            return;
        }

        if (url.includes('scheduler/dashboard') || url.includes('scheduler/template-wizard')) {
            this.currentItem = 'DASHBOARD';
            this._projectStatusUpdaterService.updateIfNeeded(this.project, 'Cost Plan').subscribe();
            return;
        }

        if (url.includes('quoter/dashboard')) {
            this.currentItem = 'COST_PLAN';
            return;
        }

        if (url.includes('quoter/quote')) {
            this.currentItem = 'QUOTE';
            return;
        }

        if (url.includes('scheduler/valuations') || url.includes('quoter/valuations')) {
            this.currentItem = 'VALUATIONS';
            return;
        }

        this.currentItem = null;
    }

    public setCurrentProject(project: IProject) {
        this.project = project;
        this.currentItem = this._currentMenuItemStorageService.retrieve(this.project?.id) as ProjectNavItemType;
    }

    public resetProject(): void {
        this.project = null;
    }

    public navigate(project: IProject = this.project) {
        if (project == null) {
            return;
        }

        if (!this._authServerProvider.getProxyAdmin() && project.estimatingAddOn?.find(addOn => addOn.status === 'PENDING')) {
            this._estimatorsWorkingOnProjectModalService.showModal();
            return;
        }

        this.project = project;

        switch (this.currentItem) {
            case 'PROJECT_INFO':
                this._router.navigate([this.isSchedulerView() ? 'scheduler' : 'quoter', 'project-details', this.project.id]);
                break;
            case 'AREAS':
                this._router.navigate(['scheduler', 'edit-schedule-areas', this.project.id]);
                break;
            case 'COST_PLAN':
                this._router.navigate(['quoter', 'dashboard', this.project.id]);
                break;
            case 'DASHBOARD':
                debugger
                this._router.navigate(['scheduler', 'dashboard', this.project.id]);
                break;
            case 'SCOPE':
                this._router.navigate(['scheduler', 'scope', this.project.id]);
                break;
            case 'SCHEDULE':
                this._router.navigate(['scheduler', 'schedule', this.project.id]);
                break;
            case 'QUOTES':
                this._router.navigate(['scheduler', 'quotes', this.project.id]);
                break;
            case 'QUOTE':
                this._router.navigate(['quoter', 'quote', this.project.id]);
                break;
            case 'VALUATIONS':
                this._router.navigate([this.isSchedulerView() ? 'scheduler' : 'quoter', 'valuations', this.project.id]);
                break;
            default:
                this._router.navigate([this.isSchedulerView() ? 'scheduler' : 'quoter', 'dashboard', this.project.id]);
                break;
        }
    }

    isSchedulerView(): boolean {
        return this.projectViewAs != null ? this.projectViewAs === 'VIEW_AS_SCHEDULER' : this._accountService.isScheduler();
    }

    isHomeownerView(): boolean {
        return this._accountService.isHomeowner();
    }
}
