<div class="modal-header">
    <div class="flex gap-10 items-center">
        <h2 class="m-b-0">
            Add Task
        </h2>
        <i class="material-icons cursor-pointer"
           [ngbTooltip]="infoText">
            info_outline
        </i>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form"
          name="form"
          novalidate>
        <div class="flex flex-row gap-40 flex-wrap">
            <div class="flex-1 min-w-200">
                <div class="form-group">
                    <label class="form-control-label"
                           for="name">
                        Task
                    </label>
                    <div class="form-line">
                        <input
                            class="af-input"
                            formControlName="name"
                            name="name"
                            id="name"
                            placeholder="Task name"
                            type="text"
                            autocomplete="off"
                            autofocus>
                    </div>
                    <label *ngIf="submitted && form.controls.name.errors.required"
                           class="error">
                        This field is required.
                    </label>
                </div>
            </div>

            <div class="flex-1 min-w-200"
                 *ngIf="showStagesSelect">
                <div class="form-group">
                    <label class="form-control-label">
                        Stage
                    </label>

                    <bp-select-input
                        *ngIf="stagesSelectInputData && stagesSelectInputData.data?.length"
                        (onSelectionChange)="onStageSelectionChange($event)"
                        [data]="stagesSelectInputData"
                        [removeUnderlines]="false"
                        additionalClasses="heght-auto"
                        class="select-stage-id">
                    </bp-select-input>
                </div>
            </div>

            <div class="flex-1 min-w-200">
                <div class="form-group">
                    <label class="form-control-label">
                        Specification
                    </label>

                    <div class="form-line">
                        <input
                            class="af-input"
                            formControlName="specification"
                            name="specification"
                            id="specification"
                            placeholder="Specification"
                            type="text"
                            autocomplete="off">
                    </div>
                </div>
            </div>

            <div class="min-w-90">
                <div class="form-group">
                    <label class="form-control-label"
                           for="laborRate">
                        Labour<br>Cost/unit
                    </label>
                    <div class="form-line">
                        <input
                            class="af-input p-l-15"
                            formControlName="laborRate"
                            name="laborRate"
                            id="laborRate"
                            placeholder="Labour Cost/unit"
                            type="number"
                            autocomplete="off">
                        <span class="pound-symbol">&pound;</span>
                    </div>
                </div>
            </div>

            <div class="min-w-90">
                <div class="form-group">
                    <label class="form-control-label"
                           for="materialRate">
                        Material<br>Cost/unit
                    </label>
                    <div class="form-line">
                        <input
                            class="af-input p-l-15"
                            formControlName="materialRate"
                            name="materialRate"
                            id="materialRate"
                            placeholder="Material Cost/unit"
                            type="number"
                            autocomplete="off">
                        <span class="pound-symbol">&pound;</span>
                    </div>
                </div>
            </div>

            <div class="min-w-90">
                <div class="form-group">
                    <label class="form-control-label"
                           for="materialRate">
                        Total<br>Cost/unit
                    </label>
                    <div class="form-line">
                        <input
                            class="af-input p-l-15 no-border-bottom"
                            formControlName="total"
                            name="total"
                            id="total"
                            placeholder="Total Cost/unit"
                            disabled="true"
                            type="number"
                            autocomplete="off">
                        <span class="pound-symbol">&pound;</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer flex gap-10 justify-content-end">
    <button (click)="clear()"
            class="btn btn-default btn-border-radius waves-effect"
            data-dismiss="modal"
            type="button">
        Cancel
    </button>

    <button class="btn btn-primary btn-border-radius waves-effect"
            type="button"
            (click)="addTask()"
            [disabled]="!form.valid">
        Add Task
    </button>
</div>

