import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';

@Injectable({
    providedIn: 'root'
})
export class CheckIfScheduleRawIsGrayedService {
    constructor() {
    }

    public isGrayed(stage: IStage, element: IElement, scheduleTask: IScheduleTask, showSubStages: boolean): boolean {
        let taskRowElements: Element[] = [];

        if (showSubStages) {
            document
                .querySelectorAll(`#stage-container_${stage.id} #element-container_${element.id}`).forEach((el) => {
                const htmlCollection = el.getElementsByClassName('schedule-task-row-which-can-be-grayed');
                for (let i = 0; i < htmlCollection.length; i++) {
                    taskRowElements.push(htmlCollection.item(i));
                }
            });
        } else {
            document
                .querySelectorAll(`#stage-container_${stage.id}`).forEach((el) => {
                const htmlCollection = el.getElementsByClassName('schedule-task-row-which-can-be-grayed');
                for (let i = 0; i < htmlCollection.length; i++) {
                    taskRowElements.push(htmlCollection.item(i));
                }
            });
        }

        const index = _.findIndex(taskRowElements, { id: `schedule-task-row_${scheduleTask.id}` });

        if (index > -1) {
            return index % 2 === 0;
        } else {
            return taskRowElements.length % 2 !== 0;
        }
    }

    public isGrayedCssElement(cssElementId: number, scheduleTask: IScheduleTask): boolean {
        let taskRowElements = document
            .querySelector(`#css-element-container_${cssElementId || -1}`)
            .getElementsByClassName('schedule-task-row-which-can-be-grayed');

        const index = _.findIndex(taskRowElements, { id: `schedule-task-row_${scheduleTask.id}` });

        if (index > -1) {
            return index % 2 === 0;
        } else {
            return taskRowElements.length % 2 !== 0;
        }
    }
}
