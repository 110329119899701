import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { IInvitation, IInvitationCompanyInfo } from 'app/shared/model/invitation.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';
import { lastValueFrom, Observable, Observer } from 'rxjs';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import {
    ScheduleComparisonScreenNavigatorService
} from 'app/shared/services/schedule-comparison-screen-navigator.service';
import {
    RequestProjectQuoterModalService
} from 'app/flows/scheduler/components/request-project-quoter-modal/request-project-quoter-modal.service';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import {
    UpdateInvitationVersionStatusCheckingService
} from 'app/shared/services/update-invitation-version-status-checking.service';
import { AccountService } from 'app/core/auth/account.service';
import {
    BuilderHasBeenInvitedToQuoteModalService
} from 'app/flows/scheduler/components/builder-has-been-invited-to-quote-modal/builder-has-been-invited-to-quote-modal.service';
import { IQuoter } from 'app/shared/model/quoter.model';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
    selector: 'bp-project-details-invitations',
    templateUrl: './project-details-invitations.component.html',
    styleUrls: ['project-details-invitations.scss']
})
export class ProjectDetailsInvitationsComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @Input() project: IProject;

    @Output() onDefaultQuoterChangedEvent = new EventEmitter<void>();
    @Output() onChangedEvent = new EventEmitter<void>();

    protected invitations: IInvitation[];
    protected newInvitation: IInvitationCompanyInfo = null;

    protected alreadyInvitedCompaniesInputData: SelectInputData;

    protected showCompareQuotesButton = false;
    protected showDefaultQuoterButton = false;
    protected isShowAverageRatesButtonEnabled = false;
    protected isUpdateAllToLatestVersionButtonEnabled = false;

    protected inProcessLoadInvitation = false;
    protected inProcessLoadAlreadyInvitedCompanies = false;
    protected inProcessSentInvitation = false;
    protected inProcessAddingDefaultQuoter = false;
    protected inProcessAcceptQuote = false;
    protected inProcessRemoveInvitation = false;
    protected inProcessReSubmitQuoter = false;
    protected showReSubmit = false;

    protected selectCompanyMode = true;
    protected readOnly = true;

    protected possibleQuoters: IQuoter[] = [];

    constructor(
        private projectApi: ProjectApi,
        private alertService: BpAlertService,
        private invitationService: InvitationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private bpOnErrorService: BpOnErrorService,
        private route: ActivatedRoute,
        private scheduleComparisonScreenNavigatorService: ScheduleComparisonScreenNavigatorService,
        private requestProjectQuoterModalService: RequestProjectQuoterModalService,
        private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
        private updateInvitationVersionStatusCheckingService: UpdateInvitationVersionStatusCheckingService,
        public accountService: AccountService,
        private builderHasBeenInvitedToQuoteModalService: BuilderHasBeenInvitedToQuoteModalService
    ) {
    }

    private get invitationOfNotLatestVersion(): IInvitation[] {
        return this.invitations.filter(invitation => {
            return !(invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == this.project.version)
        });
    }

    ngOnInit(): void {
        this.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.project);

        this.invitationService.queryPossibleQuoterList(this.project.id)
            .subscribe((res) => {
                this.possibleQuoters = res.body;
            });

        this.loadInvitations().subscribe(() => {
            this.fillAlreadyInvitedCompaniesSearchBySelectInputData();
        });

        this.route.queryParams.subscribe(params => {
            this.showReSubmit = params['showReSubmit'] === 'true';
        });
    }

    onInviteBuilderClick(): void {
        this.newInvitation = {};
    }

    onSendInviteClick(): void {
        this.inProcessSentInvitation = true;

        this.invitationService
            .sendInvitation(this.project.id, this.newInvitation.company, this.newInvitation.email, this.newInvitation.phone)
            .pipe(
                finalize(() => {
                    this.inProcessSentInvitation = false;
                    this.selectCompanyMode = true;
                })
            )
            .subscribe(
                (res: HttpResponse<any>) => {
                    const update = () => {
                        this.newInvitation = null;
                        this.invitations.push(res.body);
                        this.fillAlreadyInvitedCompaniesSearchBySelectInputData();
                        this.onChangedEvent.emit();

                    }

                    if (!this.accountService.doNotShowAgainSendInviteReminder()) {
                        this.builderHasBeenInvitedToQuoteModalService.open().result.then(() => {
                            update();
                        });
                    } else {
                        this.alertService.success('Invitation successfully sent');
                        update();
                    }
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    onRemoveInvitationClick(invitation: IInvitation): void {
        this.inProcessRemoveInvitation = true;

        this.invitationService
            .delete(invitation.id)
            .pipe(
                finalize(() => {
                    this.inProcessRemoveInvitation = false;
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Invitation successfully removed');
                    this.loadInvitations().subscribe(() => {
                        this.onChangedEvent.emit();
                    });
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    onCompareQuotesClick(): void {
        this.scheduleComparisonScreenNavigatorService.navigate(this.project, this.invitations);
    }

    onRequestProfessionalClick(): void {
        this.requestProjectQuoterModalService.open(this.project.id);
    }

    addDefaultQuoter(): void {
        this.inProcessAddingDefaultQuoter = true;

        this.invitationService
            .addDefaultQuoters(this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessAddingDefaultQuoter = false;
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Invitation default quoter success');
                    this.newInvitation = null;
                    this.loadInvitations().subscribe(() => {
                        this.onChangedEvent.emit();
                    });
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    onAcceptQuoteButtonClick(invitation: IInvitation): void {
        this.inProcessAcceptQuote = true;

        this.invitationService
            .acceptQuote(invitation.id)
            .pipe(
                finalize(() => {
                    this.inProcessAcceptQuote = false;
                })
            )
            .subscribe(
                () => {
                    this.project.status = 'Quote Accepted';
                    this.alertService.success('Invitation was accepted');
                    this.loadInvitations().subscribe(() => {
                        this.onChangedEvent.emit();
                    });
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    onDefaultQuoterChanged(invitation: IInvitation): void {
        this.projectApi.updateDefaultQuoter(this.project.id, invitation.quoterId).subscribe(() => {
            this.project.defaultQuoter = this.project.defaultQuoter || {};
            this.project.defaultQuoter.id = invitation.quoterId;
            this.onDefaultQuoterChangedEvent.emit();
            this.onChangedEvent.emit();
        });
    }

    onGoToIndividualQuoteClick(invitation: IInvitation): void {
        if (invitation.isSelfQuoter) {
            this.router.navigate(['quoter', 'quote', this.project.id]);
        } else {
            this.router.navigate(['../../individual-quote', this.project.id, invitation.quoterId], { relativeTo: this.activatedRoute });
        }
    }

    canAcceptQuote(invitation: IInvitation): boolean {
        return invitation.quoterId != null && invitation.status === 'QUOTED' && !invitation.isDefault;
    }

    canViewIndividualQuote(invitation: IInvitation): boolean {
        return invitation.quoterId != null && (invitation.isDefault || _.indexOf(['QUOTED', 'VARIATIONS_MADE', 'ACCEPTED'], invitation.status) > -1);
    }

    canInviteBuilder(): boolean {
        return _.indexOf(['Variations Made', 'Quote Accepted'], this.project.status) === -1;
    }

    isInvitationDefault(invitation: IInvitation): boolean {
        if (!this.project.defaultQuoter) {
            return false;
        }

        return invitation.quoterId === this.project.defaultQuoter.id;
    }

    canReSubmitQuoter(): boolean {
        return this.showReSubmit;
    }

    reSubmitQuoter(invitation: IInvitation): void {
        this.inProcessReSubmitQuoter = true;

        this.projectApi
            .reSubmitQuoter(this.project.id, invitation.quoterId)
            .pipe(
                finalize(() => {
                    this.inProcessReSubmitQuoter = false;
                })
            )
            .subscribe(() => {
                this.loadInvitations().subscribe(() => {
                    this.onChangedEvent.emit();
                });
            });
    }

    onUpdateToLatestVersionClick(invitation: IInvitation): void {
        this.updateToLatestVersion(invitation).then(() => {
            this.updateIsUpdateAllToLatestVersionButtonEnabled();
        });
    }


    isInvitationVersionTheSameAsProjectVersion(invitation: IInvitation): boolean {
        return invitation.majorVersion + '.' + invitation.minorVersion === this.project.version;
    }

    onMaterialSelectionChange(event): void {
        if (event != null) {
            this.newInvitation = event;
        }
    }

    isDefaultQuoterCheckBoxDisabled(invitation: IInvitation): boolean {
        return (!invitation.isDefault && (invitation.status === 'SENT' || invitation.status === 'IN_PROGRESS'));
    }

    isMe(invitation: IInvitation): boolean {
        return this.accountService.getEmail() === invitation.email;
    }

    protected onUpdateAllToLatestVersionClick(): void {
        const promises = this.invitationOfNotLatestVersion.map((invitation) => this.updateToLatestVersion(invitation));
        if (!promises.length) {
            return;
        }

        this.blockUI.start('Please wait...');

        Promise.all(promises).then(() => {
            this.updateIsUpdateAllToLatestVersionButtonEnabled();
        }).finally(() => {
            this.blockUI.stop();
        })
    }

    protected onShowAverageRatesButtonClick(): void {
        this.blockUI.start('Please wait...');
        this.invitationService.addDefaultQuoters(this.project.id).pipe(finalize(() => {
            this.blockUI.stop();
        })).subscribe(() => {
            this.loadInvitations().subscribe(() => {
                this.onChangedEvent.emit();
            });
        })
    }

    private loadInvitations(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.inProcessLoadInvitation = true;

            this.invitationService
                .query(this.project.id)
                .pipe(
                    finalize(() => {
                        this.inProcessLoadInvitation = false;
                    })
                )
                .subscribe((res: HttpResponse<IInvitation[]>) => {
                    debugger
                    this.isShowAverageRatesButtonEnabled = _.find(res.body, (invitation: IInvitation) => invitation.status === 'NOT_INVITED') != null;

                    this.invitations = _.orderBy(
                        res.body.filter(invitation => invitation.status !== 'NOT_INVITED'), ['isDefault', 'id'], ['desc', 'asc']);

                    this.updateIsUpdateAllToLatestVersionButtonEnabled();

                    _.each(_.filter(this.invitations, { processing: true }), (invitation: IInvitation) => {
                        this.updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(this.project, invitation).then(() => {
                            this.onChangedEvent?.emit();
                        })
                    });

                    _.each(
                        _.filter(
                            this.invitations,
                            (invitation: IInvitation) => invitation.isDefault || this.project.defaultQuoter.id === invitation.quoterId
                        ),
                        (invitation: IInvitation) => {
                            this.updateInvitationTotal(invitation);
                        }
                    );

                    this.showCompareQuotesButton =
                        _.find(
                            this.invitations,
                            (invitation: IInvitation) =>
                                invitation.quoterId != null && (invitation.status === 'QUOTED' || invitation.status === 'ACCEPTED')
                        ) != null;

                    this.showDefaultQuoterButton = _.find(this.invitations, (invitation: IInvitation) => invitation.isDefault) == null;

                    if (this.invitations.length === 0) {
                        this.project.status = 'Draft';
                    }

                    observer.next();
                    observer.complete();
                });
        });
    }

    private fillAlreadyInvitedCompaniesSearchBySelectInputData(): void {
        this.inProcessLoadAlreadyInvitedCompanies = true;

        this.invitationService
            .previous()
            .pipe(
                finalize(() => {
                    this.inProcessLoadAlreadyInvitedCompanies = false;
                })
            )
            .subscribe((res: HttpResponse<IInvitationCompanyInfo[]>) => {
                const alreadyInvitedCompanies = _.filter(res.body, (ic: IInvitationCompanyInfo) => {
                    return _.findIndex(this.invitations, (inv: IInvitation) => inv.email.toLowerCase() === ic.email.toLowerCase()) === -1;
                });

                this.alreadyInvitedCompaniesInputData = {
                    indexProperty: 'company',
                    titleProperty: 'company',
                    data: alreadyInvitedCompanies,
                    dropdownPosition: 'bottom',
                    clearable: false,
                    onEnterClickHandler: this.onCompaniesSelectInputEnterClick.bind(this)
                };
            });
    }

    private updateInvitationTotal(invitation: IInvitation): void {
        this.projectApi.getTotalForProjectByQuoter(this.project.id, invitation.quoterId).subscribe((res: HttpResponse<number>) => {
            invitation.total = res.body;
        });
    }

    private onCompaniesSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            this.newInvitation = { company: currentTextValue };
            this.selectCompanyMode = false;
        }
    }

    private async updateToLatestVersion(invitation: IInvitation): Promise<void> {
        const res = await lastValueFrom(this.projectApi
            .updateQuoterToCurrentProjectVersion(this.project.id, invitation.quoterId));
        switch (res.body) {
            case 'UP_TO_DATE':
                invitation.majorVersion = Number(this.project.version.split('.')[0]);
                invitation.minorVersion = Number(this.project.version.split('.')[1]);
                this.onChangedEvent.emit();
                break;
            case 'IN_PROGRESS':
                invitation.processing = true;
                this.updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(this.project, invitation).then(() => {
                    this.onChangedEvent?.emit();
                });
                break;
        }
    }

    private updateIsUpdateAllToLatestVersionButtonEnabled(): void {
        this.isUpdateAllToLatestVersionButtonEnabled = this.invitationOfNotLatestVersion.length > 0;
    }
}
