import { Injectable } from '@angular/core';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { HttpResponse } from '@angular/common/http';
import { ScheduleAreaService } from 'app/shared/dataservices/schedule-area.service';

@Injectable({
    providedIn: 'root'
})
export class DefaultUnitValueUpdaterService {
    constructor(private scheduleAreaService: ScheduleAreaService) {
    }

    public setDefaultUnitValue(scheduleTask: IScheduleTask, force = false): Promise<void> {
        return new Promise((resolve) => {
            if (!force && scheduleTask.unitValue != null) {
                resolve();
                return;
            }

            if (scheduleTask.componentAreaId == null) {
                resolve();
                return;
            }

            this.scheduleAreaService
                .findDefaultUnitValue(scheduleTask.scheduleAreaId, scheduleTask.componentAreaId)
                .subscribe((res: HttpResponse<number>) => {
                    scheduleTask.unitValue = +res.body;
                    resolve();
                });
        });
    }
}
