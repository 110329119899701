import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITask } from 'app/shared/model/task.model';
import { TaskService } from 'app/shared/dataservices/task.service';

@Component({
    selector: 'bp-task-delete-dialog',
    templateUrl: './task-delete-dialog.component.html'
})
export class TaskDeleteDialogComponent {
    task: ITask;

    constructor(
        private taskService: TaskService,
        public activeModal: NgbActiveModal
    ) {}

    protected clear(): void {
        this.activeModal.dismiss('cancel');
    }

    protected confirmDelete(id: number): void {
        this.taskService.delete(id).subscribe(
            response => {
                this.activeModal.dismiss(true);
            }
        );
    }
}
