<div class="flex justify-content-start flex-wrap gap-10">
    <bp-main-view-filter
        class="d-none d-md-block"
        *ngIf="dashboardStore.inited && dashboardStore.scheduleAreaItems?.length && dashboardStore.stageItems?.length"
        [disabled]="dashboardStore?.inProcess"
        [disabledRoomMode]="true"
        (onChanged)="onMainViewFilterChangedFunc($event)"
        [initialFilterState]="dashboardStore.filterState"
        [areaItems]="dashboardStore.scheduleAreaItems"
        [stageItems]="dashboardStore.stageItems">
    </bp-main-view-filter>

    <div *ngIf="dashboardStore.project.archive"
         class="m-l-20"
         style="margin-top: 7px">
        <small class="label label-warning"
               title="Project archived">
            Archived
        </small>
    </div>

    <div class="flex-1 flex justify-content-end">
        <button (click)="onShowAverageRatesButtonClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isShowAverageRatesButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Show Average Rates"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Show Average Rates
        </button>

        <button (click)="onShowAverageRatesButtonClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isShowAverageRatesButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Show Average Rates"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                star
            </i>
        </button>

        <button (click)="copyLinkForTheClientToBuffer()"
                *ngIf="dashboardStore.dashboardMode == null"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Link"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Copy Link
        </button>

        <button (click)="copyLinkForTheClientToBuffer()"
                *ngIf="dashboardStore.dashboardMode == null"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Link"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                share
            </i>
        </button>

        <button (click)="onUpdateAllToLatestVersionClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isUpdateAllToLatestVersionButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Update Benchmarks"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Update Benchmarks
        </button>

        <button (click)="onUpdateAllToLatestVersionClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isUpdateAllToLatestVersionButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Update Benchmarks"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                update
            </i>
        </button>

        <button (click)="copyProject()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Project"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Copy Project
        </button>

        <button (click)="copyProject()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Project"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                copy_all
            </i>
        </button>

        <div *ngIf="dashboardStore.dashboardMode === 'comparison-view'"
             class="toggle-display-container m-r-10">
            <button [disabled]="dashboardStore.inProcess"
                    [popper]="bpDisplayQuotersSelector"
                    [popperShowOnStart]="false"
                    [popperAppendTo]="'body'"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'bottom'"
                    [popperPreventOverflow]="true"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperApplyClass]="'bp-popper'"
                    class="btn btn-secondary btn-border-radius toggle-display"
                    ngbTooltip="{{ 'Select quoters for comparison'}}">
                Columns <i class="material-icons">arrow_drop_down</i>
            </button>

            <popper-content #bpDisplayQuotersSelector>
                <bp-dashboard-display-quoters-selector>
                </bp-dashboard-display-quoters-selector>
            </popper-content>
        </div>


        <button [disabled]="dashboardStore?.inProcess"
                [popper]="bpExportSelector"
                [popperAppendTo]="'body'"
                [popperTrigger]="'click'"
                [popperPlacement]="'bottom'"
                [popperPreventOverflow]="true"
                [popperHideOnClickOutside]="true"
                [popperHideOnScroll]="true"
                [popperApplyClass]="'bp-popper'"
                class="btn btn-secondary btn-border-radius export m-r-10"
                ngbTooltip="Export"
                [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
            <div class="flex align-items-center gap-1">
                <i class="material-icons md-24">
                    file_download
                </i>

                <div class="d-none d-md-block flex align-items-center">
                    Export

                    <i class="material-icons md-24 cursor-pointer">
                        expand_more
                    </i>
                </div>
            </div>
        </button>

        <popper-content #bpExportSelector>
            <bp-export-selector
                [visibleItems]="getVisibleExportItems()"
                (onChanged)="onExportTypeSelected($event)">
            </bp-export-selector>
        </popper-content>

        <button (click)="onReviewByQSClick()"
                *ngIf="dashboardService.showRequestQSSupportButton"
                [disabled]="dashboardStore.project.qsReviewed != null || dashboardStore?.inProcess"
                class="d-none d-md-inline btn btn-primary btn-border-radius m-r-10"
                ngbTooltip="Request QS Support"
                type="button">
            Request QS Support
        </button>

        <button (click)="onReviewByQSClick()"
                *ngIf="dashboardService.showRequestQSSupportButton"
                [disabled]="dashboardStore.project.qsReviewed != null || dashboardStore?.inProcess"
                ngbTooltip="Request QS Support"
                class="d-md-none btn btn-secondary btn-circle review-by-qs m-r-10">
            <i class="material-icons md-24">
                apps
            </i>
        </button>
    </div>
</div>

<div class="d-md-none flex m-t-20 justify-content-center text-center">
    <bp-main-view-filter
        *ngIf="dashboardStore.inited && dashboardStore.scheduleAreaItems?.length && dashboardStore.stageItems?.length"
        [disabled]="dashboardStore?.inProcess"
        [disabledRoomMode]="true"
        (onChanged)="onMainViewFilterChangedFunc($event)"
        [initialFilterState]="dashboardStore.filterState"
        [areaItems]="dashboardStore.scheduleAreaItems"
        [stageItems]="dashboardStore.stageItems">
    </bp-main-view-filter>
</div>
