<div [ngClass]="{'no-padding-vert': !scheduleService.showSubStages, 'm-b-30': scheduleService.showSubStages}"
     *ngIf="scheduleService.showSubStages || filteredByAreaAndNotMarkedAsDeletedScheduleTasks().length > 0">

    <!-- headings if showSubstages -->
    <div class="header-row flex flex-row align-items-center gap-20 justify-content-between"
         *ngIf="scheduleService.showSubStages">
        <div class="flex flex-row align-items-center gap-20">
            <div class="header-row__reference-number reference-number"
                 [title]="element?.referenceNumber">
                {{ element?.referenceNumber }}
            </div>
            <div>
                {{ element?.element }}
            </div>
        </div>
        <div>
            {{ elementTotal | currency:'GBP' }}
        </div>
    </div>

    <div class="element-container"
         id="element-container_{{ element.id }}">
        <div class="flex flex-column gap-10"
             *ngFor="let scheduleTask of filteredByAreaAndNotMarkedAsDeletedScheduleTasks()">
            <bp-schedule-task-for-filter-by-area-grouping
                [element]="element"
                [scheduleTask]="scheduleTask"
                [stage]="stage">
            </bp-schedule-task-for-filter-by-area-grouping>
        </div>

        <bp-add-schedule-task-for-filter-by-area-grouping
            *ngIf="!scheduleService.readOnly && scheduleService.showSubStages"
            [element]="element"
            [stage]="stage"
            [area]="scheduleArea">
        </bp-add-schedule-task-for-filter-by-area-grouping>
    </div>
</div>
