import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import * as _ from 'lodash';
import {
    EditScheduleAreaSwitchMode
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-areas.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

const NUMBER_PATTERN = '^[0-9]+(\.[0-9]+)*$';
const ROUNDING_PRECISION = 2;

@Component({
    selector: 'bp-schedule-area-row',
    templateUrl: './schedule-area-row.component.html',
    styleUrls: ['schedule-area-row.scss']
})
export class ScheduleAreaRowComponent implements OnInit, OnDestroy {
    @Input() submitted: boolean;
    @Input() project: IProject;
    @Input() scheduleArea: IScheduleArea;
    @Input() mode: EditScheduleAreaSwitchMode = 'Width_Depth_Height';

    @Output() onRemove = new EventEmitter();
    @Output() onCopy = new EventEmitter();
    @Output() onStartToFill = new EventEmitter();
    @Output() onUpdate = new EventEmitter();

    protected ceilingAreaWasUpdated = false;

    protected form: FormGroup | null = null;

    protected formSubs: Subscription = new Subscription();
    protected subs: Subscription = new Subscription();

    protected get perimeter(): number {
        return _.round((this.scheduleArea.width + this.scheduleArea.depth) * 2, ROUNDING_PRECISION)
    }

    protected get wallArea(): number {
        return _.round(
            this.scheduleArea.perimeter * this.scheduleArea.height,
            ROUNDING_PRECISION
        );
    }

    protected get floorArea(): number {
        return _.round(this.scheduleArea.width * this.scheduleArea.depth, ROUNDING_PRECISION);
    }

    ngOnInit(): void {
        if (this.scheduleArea._updated) {
            this.subs.add(
                this.scheduleArea._updated.subscribe(() => {
                    this.updateForm();
                    this.onUpdate?.emit();
                })
            );
        }

        this.form = new FormGroup({
            area: new FormControl(this.scheduleArea.area, [Validators.required]),
            width: new FormControl(this.scheduleArea.width, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            depth: new FormControl(this.scheduleArea.depth, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            height: new FormControl(this.scheduleArea.height, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            wallArea: new FormControl(this.scheduleArea.wallArea || this.wallArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            floorArea: new FormControl(this.scheduleArea.floorArea || this.floorArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            ceilingArea: new FormControl(this.scheduleArea.ceilingArea || this.scheduleArea.ceilingArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            perimeter: new FormControl(this.scheduleArea.perimeter || this.perimeter, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
        });

        this.subscribeFormToUpdates();
    }

    ngOnDestroy(): void {
        this.formSubs?.unsubscribe();
        this.subs?.unsubscribe();
    }

    protected updateScheduleArea(): void {
        this.scheduleArea.area = this.form.controls.area.value.trim();
        this.scheduleArea.width = +this.form.controls.width.value;
        this.scheduleArea.depth = +this.form.controls.depth.value;
        this.scheduleArea.height = +this.form.controls.height.value;
        this.scheduleArea.wallArea = +this.form.controls.wallArea.value;
        this.scheduleArea.floorArea = +this.form.controls.floorArea.value;
        this.scheduleArea.ceilingArea = +this.form.controls.ceilingArea.value;
        this.scheduleArea.perimeter = +this.form.controls.perimeter.value;
        this.scheduleArea.doors = this.scheduleArea.doors || 1;
        this.scheduleArea.windows = this.scheduleArea.windows || 1;
        this.scheduleArea._invalid = this.form.invalid;
    }

    protected onDeleteScheduleAreaClick(): void {
        this.onRemove?.emit();
    }

    protected onCopyScheduleAreaClick(): void {
        this.onCopy?.emit();
    }

    protected tryToCreateAreaIfNeeded(): void {
        if (this.form.controls.area.value?.length) {
            if (this.scheduleArea._empty) {
                this.updateScheduleArea();
                this.onStartToFill.emit();
            }
        }
    }

    private subscribeFormToUpdates(): void {
        this.formSubs.add(this.form.controls.area.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (this.scheduleArea._empty) {
                return;
            }
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.width.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.height.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.depth.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.wallArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.ceilingArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.floorArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (!this.ceilingAreaWasUpdated) {
                this.form.controls.ceilingArea.setValue(_.round(value, ROUNDING_PRECISION)), {
                    emitEvent: false,
                    onlySelf: true
                };
            }

            this.updateScheduleArea();
            this.onUpdate.emit();
        }));

        this.formSubs.add(this.form.controls.perimeter.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.form.controls.wallArea.setValue(_.round(this.form.controls.perimeter.value * this.form.controls.height.value, ROUNDING_PRECISION), {
                emitEvent: false,
                onlySelf: true
            });
            this.updateScheduleArea();
            this.onUpdate.emit();
        }));
    }

    private updateForm(): void {
        this.formSubs?.unsubscribe();

        this.form.controls.area.setValue(this.scheduleArea.area);
        this.form.controls.width.setValue(this.scheduleArea.width);
        this.form.controls.depth.setValue(this.scheduleArea.depth);
        this.form.controls.height.setValue(this.scheduleArea.height);
        this.form.controls.wallArea.setValue(this.scheduleArea.wallArea);
        this.form.controls.floorArea.setValue(this.scheduleArea.floorArea);
        this.form.controls.ceilingArea.setValue(this.scheduleArea.ceilingArea);
        this.form.controls.perimeter.setValue(this.scheduleArea.perimeter);

        this.subscribeFormToUpdates();
    }

    private recalculateOnChangeWidthOrHeightDepth(): void {
        this.form.controls.ceilingArea.setValue(_.round(+this.form.controls.width.value * +this.form.controls.depth.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.floorArea.setValue(_.round(+this.form.controls.width.value * +this.form.controls.depth.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.perimeter.setValue(_.round(2 * (+this.form.controls.width.value + +this.form.controls.depth.value), ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.wallArea.setValue(_.round(+this.form.controls.perimeter.value * +this.form.controls.height.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
    }
}
