import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IProject } from 'app/shared/model/project.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IScheduleArea, IScheduleAreaCache } from 'app/shared/model/schedule-area.model';
import { ScheduleAreaService } from 'app/shared/dataservices/schedule-area.service';
import * as _ from 'lodash';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ITag, IUserTag } from 'app/shared/model/tag.model';
import { ProjectAreaCacheService } from 'app/shared/dataservices/project-area-cache.service';
import {
    SubmitForAnalysisModalService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/submit-for-analysis-modal/submit-for-analysis-modal.service';
import {
    SuccessfullySubmittedForAnalysisModalService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/successfully-submitted-for-analysis-modal/successfully-submitted-for-analysis-modal.service';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';
import { IProjectAttachment } from 'app/shared/model/project-attachment.model';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
    AreaDetailsModeStorageService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/area-details-mode-storage.service';
import { LocalStorageService } from 'ngx-webstorage';
import { KreoApi } from 'app/shared/dataservices/kreo.api';
import { IKreoPropsArray, IKreoSpace } from 'app/shared/model/bp.model';
import { BpMathService } from 'app/shared/services/bp-math.service';
import { FormControl } from "@angular/forms";
import { UserTagApi } from "app/shared/dataservices/user-tag-api.service";

export type ReturnPage = 'cost_plan' | 'schedule';
export type EditScheduleAreaSwitchMode = 'Width_Depth_Height' | 'WallArea_FloorArea_Perimeter';

@Component({
    selector: 'bp-edit-schedule-areas',
    templateUrl: './edit-schedule-areas.component.html',
    styleUrls: ['edit-schedule-areas.scss'],
    providers: [
        AreaDetailsModeStorageService
    ]
})
export class EditScheduleAreasComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    protected project: IProject;
    protected initialScheduleAreas: IScheduleArea[];
    protected scheduleAreas: IScheduleArea[];
    protected groupedActualScheduleAreas: { [key: number]: IScheduleArea[] } = {};
    protected returnPage: ReturnPage = 'cost_plan';
    protected inProcessLoadingScheduleAreas = false;
    protected inProcessSavingScheduleAreas = false;
    protected submitted = false;
    protected Object = Object;
    protected mode: EditScheduleAreaSwitchMode = 'Width_Depth_Height';
    protected useCache = false;
    protected autoSaveDate?: any = null;
    protected projectAttachments: IProjectAttachment[] = [];
    protected routeDataSub = Subscription.EMPTY;
    protected routeQuerySub = Subscription.EMPTY;
    protected actionWasClicked = false;
    protected dragScheduleArea?: IScheduleArea | null = null;
    protected dragIndex = -1;
    protected dragGroupKey: string = '';
    protected dropIndex = -1;
    protected createMode = false;
    protected hasKreoProjectId = false;
    protected tagControls: { [key: number]: FormControl<string> } = {};

    private _subscriptions: Subscription[] = [];
    private _userTags: IUserTag[] = [];

    constructor(
        private scheduleAreaService: ScheduleAreaService,
        private projectAreaCacheService: ProjectAreaCacheService,
        private submitForAnalysisModalService: SubmitForAnalysisModalService,
        private successfullySubmittedForAnalysisModalService: SuccessfullySubmittedForAnalysisModalService,
        private projectApi: ProjectApi,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private alertService: BpAlertService,
        private projectAttachmentsService: ProjectAttachmentsService,
        private freemiumModalService: FreemiumModalService,
        private areaDetailsModeStorageService: AreaDetailsModeStorageService,
        private $localStorage: LocalStorageService,
        private kreoApi: KreoApi,
        private mathService: BpMathService,
        private _userTagApi: UserTagApi,
        private changeDetector: ChangeDetectorRef
    ) {
    }

    protected get actionButtonText(): string {
        return this.createMode ? 'Save and Exit' : 'Save';
    }

    ngOnInit(): void {
        this.actionWasClicked = false;

        this.routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
            this.mode = this.areaDetailsModeStorageService.retrieve(this.project.id);
            this.reload();
        });

        this.routeQuerySub = this.activatedRoute.queryParams.subscribe(params => {
            if (params['returnPage']) {
                this.returnPage = params['returnPage'];
            }

            if (params['mode']) {
                this.createMode = params['mode'] === 'create';
            }
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subs => subs.unsubscribe());
        this.routeDataSub.unsubscribe();
        this.routeQuerySub.unsubscribe();
    }

    public save(calledOnExitFromScreen = false, saveToCache: boolean = false): Promise<boolean> {
        if (calledOnExitFromScreen && this.actionWasClicked) {
            return Promise.resolve(true);
        }

        if (!saveToCache) {
            this.blockUI.start('Saving..');
        }

        this.scheduleAreas.filter((item) => item != null);
        this.scheduleAreas.forEach(scheduleArea => scheduleArea._invalid = false);

        if (!this.thereWereUpdates() && !this.useCache) {
            this.blockUI.stop();
            return Promise.resolve(true);
        }

        return new Promise(resolve => {
            if (this.inProcessSavingScheduleAreas) {
                this.blockUI.stop();
                resolve(false);
                return;
            }

            this.submitted = true;

            if (!this.isFormValid()) {
                if (!calledOnExitFromScreen) {
                    this.blockUI.stop();
                    resolve(false);
                    return;
                }

                Swal.fire({
                    title: 'You have changes on the screen which are not valid',
                    text: `Do you want to exit without saving? Or prefer to fix your changes?`,
                    icon: 'warning',
                    confirmButtonText: 'Leave the page without saving',
                    showCancelButton: true,
                    cancelButtonText: 'Fix changes'
                }).then((res: any) => {
                    this.blockUI.stop();
                    resolve(res.value);
                    return;
                });
            } else {
                this.inProcessSavingScheduleAreas = true;

                if (saveToCache) {
                    this.projectAreaCacheService.update(
                        this.project.id,
                        this.actualAndNotEmptyScheduleAreas(),
                        _.map(this.scheduleAreasToRemove(), 'id'))
                        .pipe(
                            finalize(() => {
                                this.blockUI.stop();
                                this.inProcessSavingScheduleAreas = false;
                            })
                        )
                        .subscribe(
                            (res: HttpResponse<IScheduleAreaCache>) => {
                                this.autoSaveDate = res.body.timeStamp;
                                this.useCache = true;
                                resolve(true);
                            }
                        );
                } else {
                    this.scheduleAreaService
                        .batchUpdate(this.actualAndNotEmptyScheduleAreas(), _.map(this.scheduleAreasToRemove(), 'id'))
                        .pipe(
                            finalize(() => {
                                this.blockUI.stop();
                                this.inProcessSavingScheduleAreas = false;
                            })
                        )
                        .subscribe(
                            () => {
                                this.initialScheduleAreas = _.cloneDeep(this.actualAndNotEmptyScheduleAreas());
                                this.useCache = false;

                                if (!calledOnExitFromScreen) {
                                    this.alertService.success('Save successful');
                                }

                                resolve(true);
                                return;
                            }
                        );
                }
            }
        });
    }

    protected setMode(value: EditScheduleAreaSwitchMode): void {
        this.mode = value;
        this.areaDetailsModeStorageService.store(this.project.id, value);
    }

    protected onRemoveScheduleArea(scheduleArea: IScheduleArea): void {
        scheduleArea._toRemove = true;
        this.updatedGroupedActualScheduleAreas();
        this.save(false, true);
    }

    protected onCopyScheduleArea(scheduleArea: IScheduleArea): void {
        if (this.useCache) {
            this.save(false, false).then(() => {
                this.blockUI.start('Please wait...');
                lastValueFrom(this.scheduleAreaService.duplicate(this.project.id, scheduleArea)).then(() => {
                    this.reload().finally(() => {
                        this.blockUI.stop();
                    });
                });
            });
        } else {
            this.blockUI.start('Please wait...');
            lastValueFrom(this.scheduleAreaService.duplicate(this.project.id, scheduleArea)).then(() => {
                this.reload().finally(() => {
                    this.blockUI.stop();
                });
            })
        }
    }

    protected onStartToFillScheduleArea(scheduleArea: IScheduleArea): void {
        if (scheduleArea._empty) {
            delete scheduleArea._empty;
            this.addEmptyArea(scheduleArea.tag);
        }
    }

    protected onUpdate(): void {
        this.save(false, true);
    }

    protected actualAndNotEmptyScheduleAreas(): IScheduleArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IScheduleArea) => scheduleArea._toRemove !== true && !scheduleArea._empty).map((scheduleArea: IScheduleArea) => {
            const saCopy = Object.assign({}, scheduleArea);
            for (const [key, value] of Object.entries(saCopy)) {
                if (key.startsWith('_')) {
                    delete saCopy[key];
                }
            }
            return saCopy;
        });
    }

    protected scheduleAreasToRemove(): IScheduleArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IScheduleArea) => scheduleArea.id != null && scheduleArea._toRemove);
    }

    protected onActionClick(): void {
        this.actionWasClicked = true;

        this.save(false, false).then(() => {
            if (this.createMode) {
                switch (this.returnPage) {
                    case 'cost_plan':
                        this.router.navigate(['../../dashboard', this.project.id], { relativeTo: this.activatedRoute });
                        break;
                    case 'schedule': {
                        this.router.navigate(['../../schedule', this.project.id], { relativeTo: this.activatedRoute });
                        break;
                    }
                }
            } else {
                this.reload();
            }
        })
    }

    protected invalid(): boolean {
        return (this.scheduleAreas || []).find((sa) => {
            return sa._invalid
        }) != null;
    }

    protected inProcess(): boolean {
        return this.inProcessLoadingScheduleAreas || this.inProcessSavingScheduleAreas;
    }

    protected onUploadAndAutoCalculateClick(): void {
        this.freemiumModalService.verify('upload-and-auto-calculate').then((res) => {
            if (res) {
                this.submitForAnalysisModalService.showModal(this.projectAttachments).then((res) => {
                    this.refreshProjectAttachments();

                    if (res) {
                        this.successfullySubmittedForAnalysisModalService.showModal();
                    }
                });
            }
        });
    }

    protected createAreasFromKreo(): void {
        this.blockUI.start('Creating areas from Kreo..');

        this.kreoApi.createAreasFromKreo(this.project.id).subscribe((res) => {
            this.reload().finally(() => {
                this.blockUI.stop();
            });
        }, (err: HttpErrorResponse) => {
            const message = err.error?.message || 'Cannot create areas from Kreo. There are no data';
            this.alertService.warning(message);
            this.blockUI.stop();
        });
    }

    protected pullMeasurementsFromKreo(): void {
        this.blockUI.start('Pulling measurements from Kreo..');

        this.kreoApi.getKreoSpace(this.project.id).subscribe((res) => {
            const kreoSpace: IKreoSpace = res;

            this.scheduleAreas.forEach((scheduleArea, index) => {
                    for (const [key, value] of Object.entries(kreoSpace)) {
                        const kreoPropsArray: IKreoPropsArray = kreoSpace[key];
                        if (kreoPropsArray != null) {
                            const kreoProps = kreoPropsArray.find((kp) => kp.props.name === scheduleArea.area);
                            if (kreoProps?.props) {
                                if (kreoProps.props.height !== 0) {
                                    scheduleArea.height = this.mathService.roundTo2dps(kreoProps.props.height);
                                }
                                scheduleArea.width = this.mathService.roundTo2dps(kreoProps.props.thickness);
                                scheduleArea.depth = this.mathService.roundTo2dps(kreoProps.props.length);
                                scheduleArea.floorArea = this.mathService.roundTo2dps(kreoProps.props.area);
                                scheduleArea.ceilingArea = this.mathService.roundTo2dps(kreoProps.props.area);
                                scheduleArea.wallArea = this.mathService.roundTo2dps(kreoProps.props.perimeter * scheduleArea.height)
                                scheduleArea.perimeter = this.mathService.roundTo2dps(kreoProps.props.perimeter);
                                scheduleArea._fromKreo = true;
                                scheduleArea._updated.next();
                            }
                        }
                    }
                }
            );
            this.blockUI.stop();
        }, (err: HttpErrorResponse) => {
            let message = 'Cannot pull measurement from Kreo. There are no data';
            if (err.status === 423) {
                message = err.error?.message || message;
            }
            this.alertService.warning(message);
            this.blockUI.stop();
        });
    }

    protected onAddTemplateClick(): void {
        this.router.navigate(['../../template-wizard', this.project.id], {
            queryParams: { returnPage: this.returnPage },
            relativeTo: this.activatedRoute
        });
    }

    protected isFormValid(): boolean {
        if (this.scheduleAreas == null || this.actualAndNotEmptyScheduleAreas().length === 0) {
            this.alertService.warning('Please add at least one area');
            return false;
        }

        const duplicateSchedulerAreas = this.scheduleAreas.filter((item, index) => item.area?.length && !item._empty && !item._toRemove
            && this.scheduleAreas.filter(sa => sa.area?.toLowerCase() === item.area?.toLowerCase()).length > 1);
        if (duplicateSchedulerAreas.length) {
            duplicateSchedulerAreas.forEach(scheduleArea => scheduleArea._invalid = true);
            this.alertService.warning(`Schedule names should be different: ${duplicateSchedulerAreas.map(sa => sa.area).join(', ')}`);
            return false;
        }

        return !_.find(this.actualAndNotEmptyScheduleAreas(), (scheduleArea: IScheduleArea) => {
            return scheduleArea.area == null || _.trim(scheduleArea.area).length === 0;
        });
    }

    protected getTotalAreaForTag(tagId: string): number {
        return _.sumBy(this.groupedActualScheduleAreas[tagId] || [], (scheduleArea: IScheduleArea) => {
            return +scheduleArea.floorArea || 0;
        });
    }

    protected showTableHeaderForTag(key: string): boolean {
        return _.find(this.groupedActualScheduleAreas[key], sh => sh.id != null || (sh.area != null && sh.area.length > 0)) != null;
    }

    protected getProxyAdmin(): boolean {
        return this.$localStorage.retrieve('proxyAdmin') ?? false;
    }

    protected onAreaDragStart(event: DragEvent, scheduleArea: IScheduleArea, index: number, groupKey: string): void {
        this.dragScheduleArea = scheduleArea;
        this.dragIndex = index;
        this.dragGroupKey = groupKey;
    }

    protected onAreaDragOver(event: DragEvent, scheduleArea: IScheduleArea): void {
        event.preventDefault();
        if (scheduleArea._empty) {
            event.preventDefault();
        }
    }

    protected onAreaDrop(event: DragEvent, scheduleArea: IScheduleArea, index: number, groupKey: string): void {
        event.preventDefault();

        if (scheduleArea._empty) {
            this.dragIndex = -1;
            this.dropIndex = -1;
            return;
        }

        if (this.dragGroupKey !== groupKey) {
            this.scheduleAreaService.move(scheduleArea.id, this.groupedActualScheduleAreas[this.dragGroupKey][this.dragIndex].tag.id)
                .subscribe((res) => {
                    this.reload();
                });
        }

        this.dropIndex = index;

        if (this.dragIndex === this.dropIndex) {
            this.dragIndex = -1;
            this.dropIndex = -1;
            return;
        }

        this.groupedActualScheduleAreas[groupKey].splice(this.dropIndex, 0, this.groupedActualScheduleAreas[groupKey].splice(this.dragIndex, 1)[0]);

        let pos = this.groupedActualScheduleAreas[groupKey].length - 1;

        for (let i = 0; i <= this.groupedActualScheduleAreas[groupKey].length; i++) {
            this.groupedActualScheduleAreas[groupKey][i].position = pos--;
        }

        this.onUpdate();

        this.updatedGroupedActualScheduleAreas();
        this.dragScheduleArea = null;
        this.dragIndex = -1;
        this.dropIndex = -1;
        this.changeDetector.detectChanges();
    }

    private thereWereUpdates(): boolean {
        if (this.scheduleAreasToRemove().length) {
            return true;
        }

        const actualScheduleAreas = this.actualAndNotEmptyScheduleAreas();

        if (!this.initialScheduleAreas || !this.actualScheduleAreas) {
            return false;
        }

        if (this.initialScheduleAreas.length !== actualScheduleAreas.length) {
            return true;
        }

        for (let i = 0; i < this.initialScheduleAreas.length; i++) {
            const sa1 = this.initialScheduleAreas[i];
            const sa2 = actualScheduleAreas[i];

            if (
                sa1.id !== sa2.id ||
                sa1.area !== sa2.area ||
                sa1.width !== sa2.width ||
                sa1.height !== sa2.height ||
                sa1.depth !== sa2.depth ||
                sa1.wallArea !== sa2.wallArea ||
                sa1.floorArea !== sa2.floorArea ||
                sa1.ceilingArea !== sa2.ceilingArea ||
                sa1.doors !== sa2.doors ||
                sa1.windows !== sa2.windows ||
                sa1.perimeter !== sa2.perimeter ||
                sa1.position !== sa2.position
            ) {
                return true;
            }
        }

        return false;
    }

    private actualScheduleAreas(): IScheduleArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IScheduleArea) => scheduleArea._toRemove !== true);
    }

    private setScheduleAreas(scheduleAreas: IScheduleArea[]): void {
        this.scheduleAreas = scheduleAreas;

        this.updatedGroupedActualScheduleAreas();

        Object.keys(this.groupedActualScheduleAreas).forEach(key => {
            const tag: ITag = this.groupedActualScheduleAreas[key][0].tag;
            const userTag = this._userTags.find(userTag => ('' + userTag.tag.id) === key);
            this.tagControls[key] = new FormControl(userTag?.name ?? tag.name);

            this._subscriptions.push(
                this.tagControls[key].valueChanges
                    .pipe(debounceTime(700), distinctUntilChanged()).subscribe((term: string) => {
                    if (!term) {
                        return;
                    }

                    const userTag = this._userTags.find(userTag => ('' + userTag.tag.id) === key);
                    if (userTag) {
                        userTag.name = term;
                        this._userTagApi.update(userTag).subscribe(res => {
                            userTag.name = res.body.name;
                            userTag.tag = res.body.tag;
                        });
                    } else {
                        const newUserTag: IUserTag = {
                            tag: this.groupedActualScheduleAreas[key][0].tag,
                            name: term
                        }
                        this._userTagApi.create(newUserTag).subscribe(res => {
                            this._userTags.push(res.body);
                        })
                    }

                })
            );
            this.addEmptyArea(tag);
        });

        if (_.find(this.scheduleAreas, sh => !sh.tag) == null) {
            this.addEmptyArea();
        }
    }

    private updatedGroupedActualScheduleAreas(): void {
        this.groupedActualScheduleAreas = _.groupBy(
            this.actualScheduleAreas(),
            (scheduleArea: IScheduleArea) => (scheduleArea.tag || {}).id
        );

        Object.keys(this.groupedActualScheduleAreas).forEach(key => {
            this.groupedActualScheduleAreas[key] = this.groupedActualScheduleAreas[key].sort((a, b) => {
                if (a._empty) {
                    return 1;
                }
                if (b._empty) {
                    return -1;
                }
                return -a.position + b.position;
            });
            this.groupedActualScheduleAreas[key].forEach((sa, index) => {
                if (!sa._empty && sa.id != null && (sa.position === -1 || sa.position === -2)) {
                    sa.position = Math.max(-1, this.groupedActualScheduleAreas[key].length - (index + 3));
                    // this.scheduleAreaService.position(sa, sa.position).subscribe();
                }
            })
        })

        this.onUpdate();
    }

    private loadScheduleAreas(): Promise<IScheduleArea[]> {
        return new Promise((resolve) => {
            this.inProcessLoadingScheduleAreas = true;

            this.projectApi
                .queryAvailableScheduleAreas(this.project.id)
                .pipe(
                    finalize(() => {
                        this.inProcessLoadingScheduleAreas = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IScheduleArea[]>) => {
                        resolve(res.body);
                    }
                );
        });
    }

    private addEmptyArea(tag?: ITag): void {
        const emptyArea: IScheduleArea = {
            projectId: this.project.id,
            position: -1,
            tag,
            _empty: true,
            width: 0,
            depth: 0,
            height: 0,
            wallArea: 0,
            floorArea: 0,
            ceilingArea: 0,
            doors: 1,
            windows: 1,
            perimeter: 0,
            _updated: new Subject<void>()
        };

        this.scheduleAreas.push(emptyArea);
        this.updatedGroupedActualScheduleAreas();
    }

    private reloadScheduleAreas(): Promise<void> {
        return lastValueFrom(this.projectAreaCacheService.get(this.project.id)).then((scheduleAreasCache: HttpResponse<IScheduleAreaCache>) => {
            this.useCache = true;
            scheduleAreasCache.body.scheduleAreas.forEach((scheduleArea) => {
                scheduleArea._updated = new Subject<void>();
            });
            this.setSA(scheduleAreasCache.body);
        }, async () => {
            this.useCache = false;
            const scheduleAreas = await this.loadScheduleAreas();
            scheduleAreas.forEach((scheduleArea) => {
                scheduleArea._updated = new Subject<void>();
            });
            this.initialScheduleAreas = _.cloneDeep(scheduleAreas);
            this.setScheduleAreas(scheduleAreas);
        })
    }

    private setSA(scheduleAreasCache: IScheduleAreaCache): void {
        this.initialScheduleAreas = _.cloneDeep(scheduleAreasCache.scheduleAreas);
        this.autoSaveDate = scheduleAreasCache.timeStamp;
        this.setScheduleAreas(scheduleAreasCache.scheduleAreas);
    }

    private async refreshProjectAttachments(): Promise<void> {
        const resFloorPlan = await lastValueFrom(this.projectAttachmentsService.query(this.project.id, 'FLOOR_PLAN'));
        this.projectAttachments = resFloorPlan.body;
        this.hasKreoProjectId = this.projectAttachments.find(attachment => attachment.kreoProjectId != null) != null;
    }

    private async reload(): Promise<void> {
        this.blockUI.start('Reloading...');

        this._userTagApi.query().subscribe((res) => {
            this._userTags = res.body;

            return Promise.all([
                this.reloadScheduleAreas(),
                this.refreshProjectAttachments(),
            ]);
        })

        this.blockUI.stop();
    }
}
