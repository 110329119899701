import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { UploadedFileCheckerService } from 'app/shared/services/uploaded-file-checker.service';
import { IProjectAttachment } from 'app/shared/model/project-attachment.model';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IProject } from 'app/shared/model/project.model';
import { lastValueFrom } from "rxjs";

class ImageSnippet {
    pending = false;

    constructor(public src: string, public file: File) {
    }
}

@Component({
    selector: 'bp-project-attachments-fe-only',
    templateUrl: './project-attachments-fe-only.component.html',
    styleUrls: ['project-attachments-fe-only.scss']
})
export class ProjectAttachmentsFeOnlyComponent implements OnInit {
    @Input() project: IProject;
    @Input() buttonLabel: string;
    @Input() disabled: boolean = false;
    @Input() attachments: IProjectAttachment[] = [];
    @Input() maxCountOfAttachments: number = -1;
    @Input() accept = '*/*';
    @Output() onChanged = new EventEmitter();

    protected attachmentsCopy: IProjectAttachment[] = [];
    protected selectedFiles: ImageSnippet[] = [];

    protected get attachmentCountExceeded(): boolean {
        if (this.maxCountOfAttachments === -1) {
            return false;
        }

        return this.attachmentsCopy?.length + this.selectedFiles?.length >= this.maxCountOfAttachments;
    }

    constructor(private uploadedFileCheckerService: UploadedFileCheckerService,
                private projectAttachmentsService: ProjectAttachmentsService,
                private alertService: BpAlertService) {
    }

    ngOnInit(): void {
        this.attachmentsCopy = Object.assign(this.attachmentsCopy, this.attachments);
    }

    protected processFile(imageInput: any): void {
        const file: File = imageInput.files[0];

        if (!this.uploadedFileCheckerService.check(file)) {
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            const selectedFile = new ImageSnippet(event.target.result, file);
            this.selectedFiles.push(selectedFile);
            this.onChanged.emit(_.map(this.selectedFiles, sf => sf.file));
        });

        reader.readAsDataURL(file);
    }

    protected downloadAttachment(attachment: IProjectAttachment): void {
        this.projectAttachmentsService.download(this.project.id, attachment.id)
            .subscribe((response: string) => {
            const splittedFileName = attachment.fileName.split('.');
            const ext = attachment.fileName.split('.')[splittedFileName.length - 1];

            let dataType = 'application/octet-stream';

            switch (ext.toLowerCase()) {
                case 'jpeg':
                case 'jpg':
                    dataType = 'image/jpeg';
                    break;
                case 'png':
                    dataType = 'image/png';
                    break;
                case 'gif':
                    dataType = 'image/gif';
                    break;
            }

            if (!dataType) {
                this.alertService.error('Cannot download file');
                return;
            }

            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            const blob = new Blob(binaryData, { type: dataType });
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.setAttribute('download', attachment.originalName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    protected removeAttachment(attachment: IProjectAttachment): void {
        lastValueFrom(this.projectAttachmentsService.delete(this.project.id, attachment.id)).then(() => {
            this.attachmentsCopy.splice(this.attachmentsCopy.indexOf(attachment), 1);
            this.alertService.success('Document successfully removed!');
        });
    }

    protected download(imageSnippet: ImageSnippet): void {
        const splittedFileName = imageSnippet.file.name.split('.');
        const ext = imageSnippet.file.name.split('.')[splittedFileName.length - 1];
        const blob = new Blob([imageSnippet.file], { type: imageSnippet.file.type });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.setAttribute('download', imageSnippet.file.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    protected remove(imageSnippet: ImageSnippet): void {
        const index = this.selectedFiles.indexOf(imageSnippet);
        if (index > -1) {
            this.selectedFiles.splice(index, 1);
            this.onChanged.emit(_.map(this.selectedFiles, sf => sf.file));
        }
    }
}
