import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    TaskRequestModalComponent
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.component';
import { INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';

@Injectable({providedIn: 'root'})
export class TaskRequestModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(data: INewTaskRequestCreationDTO): NgbModalRef {
        this.ngbModalRef = this.modalService.open(TaskRequestModalComponent as Component, {
            windowClass: 'bp-modal fill-width-80 task-request-modal',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.data = data;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
