<div class="row" style="height: 36px;">
    <div class="col-sm-6">
        <div (click)="toggleAreaExpand()"
             class="bp-cursor-pointer root-header">
            <span>{{ scheduleArea.area }}</span>
            <i class="material-icons">{{ scheduleArea.expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
    </div>
    <div class="col-sm-6 root-header text-right">
        <span>{{ getScheduleAreaTotal(scheduleArea) | currency:'GBP' }}</span>
    </div>
</div>

<div *ngIf="expanded"
     class="flex flex-column m-l-20 gap-10">
    <bp-schedule-stage-for-filter-by-area-grouping
        *ngFor="let stage of scheduleService.filteredStages(scheduleArea['stages'], scheduleArea); let first = first;"
        [stage]="stage"
        [first]="first"
        [scheduleArea]="scheduleArea">
    </bp-schedule-stage-for-filter-by-area-grouping>
</div>
