import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { IBuildUp, IBuildUpCategory } from 'app/shared/model/bp.model';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';
import { BuildUpCategoryApi } from 'app/shared/dataservices/build-up-category.api';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ITask } from 'app/shared/model/task.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TaskService } from 'app/shared/dataservices/task.service';
import { NgSelectComponent } from "@ng-select/ng-select";

@UntilDestroy()
@Component({
    selector: 'bp-build-up-update',
    templateUrl: './build-up-update.component.html',
    styleUrls: ['build-up-update.scss']
})
export class BuildUpUpdateComponent implements OnInit {
    @BlockUI() protected blockUI: NgBlockUI;

    protected item: IBuildUp;
    protected isSaving: boolean;

    protected tasks: ITask[] = [];

    protected newTask: ITask | null = null;
    protected addingTaskInProgress: boolean;
    protected searchInProgress = false;
    protected searchControl = new FormControl();

    @ViewChild('myNgSelect') myNgSelect: NgSelectComponent;


    protected buildUpCategories: IBuildUpCategory[];

    constructor(
        private taskService: TaskService,
        private alertService: BpAlertService,
        private buildUpApi: BuildUpApi,
        private buildUpCategoryApi: BuildUpCategoryApi,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ buildUp }) => {
            this.item = buildUp;
            this.item.categoryId = buildUp.category.id;
        });
        this.buildUpCategoryApi.query().subscribe(
            (res: HttpResponse<IBuildUpCategory[]>) => {
                this.buildUpCategories = res.body;
            }
        );

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged(),
            untilDestroyed(this))
            .subscribe((term: string) => {
                this.searchInProgress = true;
                this.taskService
                    .query({
                        page: 0,
                        size: 100,
                        searchValue: term,
                    })
                    .pipe(
                        debounceTime(500),
                        finalize(() => this.searchInProgress = false)
                    ).subscribe(
                        (res: HttpResponse<ITask[]>) => {
                            this.tasks = res.body.filter(task => this.item.tasks?.map(t => t.id).indexOf(task.id) === -1)
                        }
                    );
            });
    }

    protected onAddTaskSelectInput(event: any): void {
        this.searchControl.setValue(this.myNgSelect.searchTerm);
    }

    protected onAddTaskSelectChanged(value: ITask): void {
        if (!value) {
            return;
        }
        this.attachTask(value);
        this.tasks = [];
        this.myNgSelect.clearModel();
    }

    protected previousState(): void {
        window.history.back();
    }

    protected save(): void {
        this.isSaving = true;
        if (this.item.id !== undefined) {
            this.subscribeToSaveResponse(this.buildUpApi.update(this.item));
        } else {
            this.subscribeToSaveResponse(this.buildUpApi.create(this.item));
        }
    }

    protected trackById(index: number, item: IBuildUpCategory): number {
        return item.id;
    }

    protected detachTask(task: ITask): void {
        this.blockUI.start('Task is detached...');

        lastValueFrom(this.buildUpApi.detachTask(this.item.id, task.id))
            .finally(() => {
                this.blockUI.stop();
            }).then((res) => {
            this.reloadBuildUp();
            this.alertService.success(`Task '${task.task}' was successfully detached!`);
        })
    }

    protected attachTask(task: ITask): void {
        this.blockUI.start('Task is attached...');

        lastValueFrom(this.buildUpApi.attachTask(this.item.id, task.id))
            .finally(() => {
                this.blockUI.stop();
            }).then((res) => {
            this.reloadBuildUp();
            this.alertService.success(`Task '${task.task}' was successfully attached!`);
        })
    }

    protected reloadBuildUp(): Promise<void> {
        return lastValueFrom(this.buildUpApi.find(this.item.id)).then((res) => {
            this.item = res.body;
        })
    }

    protected addTask(): void {
        this.newTask = null;
        this.addingTaskInProgress = true;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IBuildUp>>) {
        result.subscribe(
            (res: HttpResponse<IBuildUp>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
