<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <h1>
                Task labour times
            </h1>
        </div>
    </div>

    <div class="card materials m-t-20">
        <div class="header">
            <div class="row">
                <div class="col-sm-3">
                    <span class="table-title">Task</span>
                </div>
                <div class="col-sm-3">
                    <span class="table-title">Labour</span>
                </div>
                <div class="col-sm-2">
                    <span class="table-title">Hours per unit</span>
                </div>
                <div class="col-sm-2">
                    <span class="table-title">Margin</span>
                </div>
                <div class="col-sm-2">
                </div>
            </div>

            <div class="row m-t-10">
                <div class="col-sm-12">
                    <div *ngIf="inProcess()"
                         class="text-center">
                        <strong>
                            Please wait..
                        </strong>
                    </div>

                    <div *ngFor="let labour of taskLabours;"
                         class="row m-t-5">
                        <div class="col-sm-3">
                            {{ labour.task }}
                        </div>
                        <div class="col-sm-3">
                            {{ labour.trade }}
                        </div>
                        <div class="col-sm-2">
                            {{ labour.hours  | number : '1.2-2' }}
                        </div>
                        <div class="col-sm-2">
                            {{ labour.margin * 100 }}<span class="sign">%</span>
                        </div>
                        <div class="col-sm-2">
                            <button (confirm)="onDeleteTaskLabourClick(labour)"
                                    *ngIf="labour.id && !inProcessDeleteTaskLabour"
                                    [swal]="{
                                        title: 'Are you sure?',
                                        text: 'You will not be able to recover this task labour!',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        customClass: {
                                           confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                           cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                        }
                                    }"
                                    class="delete-task btn btn-circle btn-small btn-secondary"
                                    ngbTooltip="Delete task labour">
                                <i class="material-icons md-18">delete_forever</i>
                            </button>
                        </div>
                    </div>

                    <small *ngIf="taskLabours.length === 0">
                        No items
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>
